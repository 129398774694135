import dynamic from 'next/dynamic';

import { ImageFragment } from '@hultafors/snickers/types';

import { Button } from '../Button/Button';

import {
  CampaignPlugImage,
  CampaignPlugStyled,
  Content,
  Heading,
  SmallText,
  StyledLink,
} from './LargeCampaignPlug.styled';

const Picture = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.Picture),
);

export interface LargeCampaignPlugProps {
  title?: string;
  ingress?: string;
  image?: ImageFragment;
  ctaLink?: string;
  ctaButtonText?: string;
}

export const LargeCampaignPlug: React.FC<LargeCampaignPlugProps> = ({
  title = '',
  ctaLink = '',
  image,
  ingress = '',
  ctaButtonText = '',
}) => (
  <CampaignPlugStyled>
    <StyledLink href={ctaLink}>
      <CampaignPlugImage>
        {image?.responsiveImage?.src && (
          <Picture
            url={image.responsiveImage.src || ''}
            alt={image.alt || ''}
            params={{ h: '454', w: '600' }}
            base64={image.responsiveImage?.base64}
            sources={[
              {
                images: [
                  { descriptor: '1x', params: { h: '600', w: '1180' } },
                  {
                    descriptor: '2x',
                    params: { h: '1200', q: '50', w: '2360' },
                  },
                ],
                media: '(min-width:1180px)',
              },
              {
                images: [
                  { descriptor: '1x', params: { h: '460', w: '768' } },
                  {
                    descriptor: '2x',
                    params: { h: '920', q: '50', w: '1536' },
                  },
                ],
                media: '(min-width:768px)',
              },
            ]}
          />
        )}
      </CampaignPlugImage>
      <Content image={image}>
        <Heading>{title}</Heading>
        <SmallText>{ingress}</SmallText>
        <Button className="plug-button" yellow>
          {ctaButtonText}
        </Button>
      </Content>
    </StyledLink>
  </CampaignPlugStyled>
);
