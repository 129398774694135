import { FieldWrapper } from '../FieldWrapper/FieldWrapper';
import { Label } from '../Label/Label';

import { StyledSelect, StyledSelectContainer } from './Select.styled';

export interface SelectProps {
  children?: React.ReactNode;
  id: string;
  inline?: boolean;
  options?: string[];
  label: string;
  wrapperProps?: any;
  selected?: any;
  handleChange?(): void;
}

export const Select: React.FC<SelectProps> = ({
  children,
  label,
  id,
  options = [],
  selected,
  handleChange,
}) => {
  return (
    <FieldWrapper>
      <StyledSelectContainer>
        <Label htmlFor={id} label={label} />
        <StyledSelect id={id} value={selected} onChange={handleChange}>
          {/* If children are provided, they will take
        precedence over the "options" prop */}
          {children ??
            options?.map((option) => (
              <option id={option} value={option} key={`select.${id}.${option}`}>
                {option}
              </option>
            ))}
        </StyledSelect>
      </StyledSelectContainer>
    </FieldWrapper>
  );
};
