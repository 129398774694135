import {
  fontFamilies,
  FontFamilies,
  fontSizes,
  FontSizes,
  fontWeights,
  FontWeights,
  Spacing,
} from '@hultafors/snickers/helpers';

import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
} from './HeadingGeneral.styled';

export interface HeadingGeneralProps {
  children?: React.ReactNode;
  isCentered?: boolean;
  underline?: boolean;
  headingRank?: number;
  weight?: FontWeights;
  family?: FontFamilies;
  size?: FontSizes;
  bottomSpacing?: Spacing;
  hasTopSpacing?: boolean;
  topSpacing?: Spacing;
  uppercase?: boolean;
}

export const HeadingGeneral: React.FC<HeadingGeneralProps> = ({
  children,
  isCentered,
  underline,
  headingRank,
  weight,
  family,
  size,
  bottomSpacing,
  hasTopSpacing = true,
  topSpacing,
  uppercase,
}) => {
  const as =
    headingRank === 5
      ? Heading5
      : headingRank === 4
      ? Heading4
      : headingRank === 3
      ? Heading3
      : headingRank === 2
      ? Heading2
      : Heading1;
  return (
    <Heading1
      as={as}
      $hasTopSpacing={hasTopSpacing}
      $customFontSize={size && fontSizes[size]}
      $customWeight={weight && fontWeights[weight]}
      $customFamily={family && fontFamilies[family]}
      $topSpacing={topSpacing}
      $bottomSpacing={bottomSpacing}
      $underline={underline}
      $isCentered={isCentered}
      $uppercase={uppercase}
    >
      {children}
    </Heading1>
  );
};
