import dynamic from 'next/dynamic';

import { InterviewPagePlugFragment } from '@hultafors/snickers/types';

import { PlugWrapper, RelatedArticlesStyled } from './RelatedArticles.styled';

const Grid = dynamic(() =>
  import('../Grid/Grid').then((module) => module.Grid),
);
const GridChild = dynamic(() =>
  import('../GridChild/GridChild').then((module) => module.GridChild),
);
const H3 = dynamic(() => import('../H3/H3').then((module) => module.H3));
const GenericPlug = dynamic(() =>
  import('../GenericPlug/GenericPlug').then((module) => module.GenericPlug),
);
const LinkBox = dynamic(() =>
  import('../LinkBox/LinkBox').then((module) => module.LinkBox),
);

export interface RelatedArticlesProps {
  title: string;
  readMoreLabel: string;
  article: InterviewPagePlugFragment[];
  className?: string;
  parentPageSlug: string;
}

export const RelatedArticles: React.FC<RelatedArticlesProps> = ({
  title,
  readMoreLabel,
  article: articles,
  className,
  parentPageSlug,
}) => {
  if (!articles?.[0]) {
    return null;
  }
  const article: InterviewPagePlugFragment = articles[0];
  if (!article?.pageLink) {
    return null;
  }

  const parentUrl =
    '/' +
    ['list', parentPageSlug].map((part) => part?.replace(/\//g, '')).join('/');

  let url =
    '/' +
    ['list', article.pageLink.slug]
      .map((part) => part?.replace(/\//g, ''))
      .join('/');

  if (parentPageSlug) {
    url =
      '/' +
      ['list', parentPageSlug, article.pageLink.slug]
        .map((part) => part?.replace(/\//g, ''))
        .join('/');
  }

  return (
    <RelatedArticlesStyled className={className}>
      <Grid
        columnGap={[
          { columnGap: 15 },
          { breakpoint: 'mobileMax', columnGap: 20 },
        ]}
        columns={[{ columns: 1 }, { breakpoint: 'mobileMax', columns: 12 }]}
      >
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 12 },
          ]}
        >
          <H3 uppercase center>
            {title}
          </H3>
        </GridChild>

        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 7 },
            { breakpoint: 'desktopMedium', columns: 6 },
          ]}
        >
          <PlugWrapper>
            <GenericPlug
              title={article.title || ''}
              image={article.image || undefined}
              intro=""
              url={url}
              label={article.interviewLabel || ''}
              name={article.interviewName || ''}
              large
              sizes="(min-width: 767px) 50vw, 100vw"
            />
          </PlugWrapper>
        </GridChild>

        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 4, start: 9 },
            { breakpoint: 'desktopMedium', columns: 3, start: 10 },
          ]}
        >
          {!!parentPageSlug && (
            <div className="alignBottom">
              <LinkBox url={parentUrl} label={readMoreLabel} />
            </div>
          )}
        </GridChild>
      </Grid>
    </RelatedArticlesStyled>
  );
};
