import { useMemo } from 'react';

import { useGlobal } from '@hultafors/snickers/hooks';
import {
  SnickersProduct,
  WorkwearGuideFilter,
  WorkwearGuideSelection,
  WorkwearGuideStep,
} from '@hultafors/snickers/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { GuideHero } from '../GuideHero/GuideHero';
import { H4 } from '../H4/H4';
import { Loader } from '../Loader/Loader';
import { Pager } from '../Pager/Pager';
import { Product } from '../Product/Product';
import { WorkwearGuideBottomSection } from '../WorkwearGuideBottomSection/WorkwearGuideBottomSection';
import { WorkwearGuideContainer } from '../WorkwearGuideContainer/WorkwearGuideContainer';
import { WorkwearGuideInner } from '../WorkwearGuideInner/WorkwearGuideInner';

import {
  BottomItem,
  BottomList,
  BottomTitle,
  ResultContainer,
} from './WorkwearGuideResult.styled';

export interface WorkwearGuideResultProps {
  changeStep(stepNumber: number): void;
  loadMore(): void;
  currentStep: WorkwearGuideStep;
  numberOfSteps: number;
  selection: WorkwearGuideSelection;
  perfectMatch: SnickersProduct[];
  semiPerfectMatch: SnickersProduct[];
  lessPerfectMatch: SnickersProduct[];
  maxMatchingToShow: number;
  heroTitle: string;
  heroIntro?: string;
  backText?: string;
  nextText?: string;
  personalFilterText?: string;
  perfectText: string;
  semiPerfectText: string;
  lessPerfectText: string;
  pagerLoading: boolean;
  pagerData: any;
  newText?: string;
  loadMoreText?: string;
  introText?: string;
  loading?: boolean;
}

export const WorkwearGuideResult: React.FC<WorkwearGuideResultProps> = ({
  changeStep,
  loadMore,
  currentStep,
  numberOfSteps,
  selection,
  perfectMatch,
  semiPerfectMatch,
  lessPerfectMatch,
  maxMatchingToShow,
  heroTitle,
  heroIntro,
  backText,
  nextText,
  personalFilterText,
  perfectText,
  semiPerfectText,
  lessPerfectText,
  pagerLoading,
  pagerData,
  newText,
  loadMoreText,
  introText,
  loading,
}) => {
  const { global } = useGlobal();
  const hasFilters = useMemo<boolean>(() => {
    return !!selection.filters.flatMap(({ values }) => values).length;
  }, [selection.filters]);

  const onClick: React.MouseEventHandler = () => {
    loadMore();
  };

  const resultFilterMapper = (item: WorkwearGuideFilter, i: number) => {
    return (
      item.values?.map((child, j: number) => {
        return (
          <BottomItem key={`Filter-${i}-${j}`}>{child.description}</BottomItem>
        );
      }) || []
    );
  };
  return (
    <WorkwearGuideContainer>
      <WorkwearGuideInner>
        <GuideHero
          title={heroTitle}
          introText={introText}
          isBig
          isResult
          backText={backText}
          currentStep={currentStep.number}
          numberOfSteps={numberOfSteps}
          changeStep={changeStep}
          guideType="workwear"
        />

        {perfectMatch.length ||
        semiPerfectMatch.length ||
        lessPerfectMatch.length ? (
          <>
            {perfectMatch.length > 0 && (
              <ResultContainer>
                <H4 center>{perfectText}</H4>
                <Grid
                  columns={[
                    { columns: 1 },
                    { breakpoint: 'mobileMax', columns: 1 },
                  ]}
                >
                  {perfectMatch.map((product, i) => {
                    return (
                      <GridChild
                        key={i}
                        columnSpan={[
                          { columns: 1 },
                          { breakpoint: 'mobileMax', columns: 1 },
                        ]}
                      >
                        <Product
                          product={product}
                          badgeText={newText || ''}
                          rrpLabel={global.rrpLabel || ''}
                          rrpIncludingVatLabel={
                            global.rrpIncludingVatLabel || ''
                          }
                        />
                      </GridChild>
                    );
                  })}
                </Grid>
                {perfectMatch.length > maxMatchingToShow && (
                  <Pager
                    onClick={onClick}
                    buttonText={loadMoreText || ''}
                    ofText="of text"
                    productsText=""
                    pagerData={pagerData}
                    loading={pagerLoading}
                  />
                )}
              </ResultContainer>
            )}

            {semiPerfectMatch.length > 0 && (
              <ResultContainer>
                <H4 center>{semiPerfectText}</H4>
                <Grid
                  columns={[
                    { columns: 4 },
                    { breakpoint: 'mobileMax', columns: 6 },
                  ]}
                >
                  {semiPerfectMatch.map((product, i) => {
                    return (
                      <GridChild
                        key={i}
                        columnSpan={[
                          { columns: 2 },
                          { breakpoint: 'mobileMax', columns: 3 },
                        ]}
                      >
                        <Product
                          product={product}
                          isSmall
                          badgeText={newText || ''}
                          rrpLabel={global.rrpLabel || ''}
                          rrpIncludingVatLabel={
                            global.rrpIncludingVatLabel || ''
                          }
                        />
                      </GridChild>
                    );
                  })}
                </Grid>
              </ResultContainer>
            )}

            {lessPerfectMatch.length > 0 && (
              <ResultContainer>
                <H4 center>{lessPerfectText}</H4>
                <Grid
                  columns={[
                    { columns: 4 },
                    { breakpoint: 'mobileMax', columns: 6 },
                  ]}
                >
                  {lessPerfectMatch.map((product, i) => {
                    return (
                      <GridChild
                        key={i}
                        columnSpan={[
                          { columns: 2 },
                          { breakpoint: 'mobileMax', columns: 3 },
                        ]}
                      >
                        <Product
                          product={product}
                          isSmall
                          badgeText={newText || ''}
                          rrpLabel={global.rrpLabel || ''}
                          rrpIncludingVatLabel={
                            global.rrpIncludingVatLabel || ''
                          }
                        />
                      </GridChild>
                    );
                  })}
                </Grid>
              </ResultContainer>
            )}
          </>
        ) : (
          <div className="LoaderWrapper">
            <Loader small />
          </div>
        )}

        {hasFilters && (
          <WorkwearGuideBottomSection result>
            <BottomTitle>{personalFilterText}</BottomTitle>
            <BottomList>
              {selection.filters.flatMap(resultFilterMapper)}
            </BottomList>
          </WorkwearGuideBottomSection>
        )}
      </WorkwearGuideInner>
    </WorkwearGuideContainer>
  );
};
