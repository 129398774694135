import styled, { css } from 'styled-components';

import { colors, fontWeights, spacing } from '@hultafors/snickers/helpers';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-inline: 20px;
  padding-block-end: 20px;
`;

export const RoundBox = styled.label<{ $selected?: boolean }>`
  position: relative;
  display: inline-flex;
  height: 40px;
  align-items: center;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%);
  border-radius: 20px;
  margin-inline-end: ${spacing.tiny};
  margin-block-end: 8px;
  cursor: pointer;
  border: 2px solid transparent;
  box-sizing: border-box;

  ${({ $selected }) =>
    $selected &&
    css`
      border-color: ${colors.highlightBlue};
    `}
`;

export const RoundBoxInput = styled.input`
  appearance: none;
  visibility: hidden;
`;

export const RoundBoxLabel = styled.span`
  height: 100%;
  width: 100%;
  padding: 0 16px;
  line-height: 36px;
  position: relative;
  font-weight: ${fontWeights.medium};
  font-size: 14px;
  margin-left: 22px;
  white-space: nowrap;

  ${RoundBoxInput}:checked ~ &::before {
    background: ${colors.workwearBlack};
  }

  &::before {
    content: '';
    display: inline-flex;
    inline-size: 12px;
    block-size: 12px;
    background: ${colors.gray4};
    border-radius: 50%;
    position: absolute;
    inset-block-start: 50%;
    transform: translateY(-50%);
    inset-inline-start: -6px;
  }
`;
