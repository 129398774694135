import { useId } from 'react';

import dynamic from 'next/dynamic';

import {
  BigPlugsBlockFragment,
  CampaignPagePlugFragment,
  ContentListPagePlugFragment,
  ContentPagePlugFragment,
  FindRetailersPagePlugFragment,
  isCampaignPagePlug,
  isContentListPagePlug,
  isContentPagePlug,
  isFindRetailersPagePlug,
} from '@hultafors/snickers/types';

import { BigPlugsGridStyled, Title } from './big-plugs-block.styled';

const GenericLargePlug = dynamic(() =>
  import('../GenericLargePlug/GenericLargePlug').then(
    (module) => module.GenericLargePlug,
  ),
);

export type BigPlugsBlockProps = BigPlugsBlockFragment;

export const BigPlugsBlock: React.FC<BigPlugsBlockProps> = ({
  title,
  plugs,
}) => {
  const uid = useId();
  const sizesDesktop = [
    '(min-width: 1480px) 705px',
    '(min-width: 768px) 50vw',
    '100vw',
  ].join(', ');
  function plugsMapper(
    plug:
      | ContentListPagePlugFragment
      | ContentPagePlugFragment
      | CampaignPagePlugFragment
      | FindRetailersPagePlugFragment,
  ) {
    const key = `BigPlugsBlock-${uid}-${plug.id}`;
    const label = plug.label;
    const name: string | undefined | null = '';
    let title: string | undefined | null = '';
    let plugIngress: string | undefined | null = '';
    let image;
    let desktopImage;

    if (
      isContentPagePlug(plug) ||
      isContentListPagePlug(plug) ||
      isCampaignPagePlug(plug) ||
      isFindRetailersPagePlug(plug)
    ) {
      title = plug.teaserTitle;
      plugIngress = plug.teaserDescription;
      image = plug.teaserImage;
      desktopImage = plug.desktopTeaserImage;
    }

    let url: string | undefined;

    if (!isFindRetailersPagePlug(plug)) {
      url =
        '/' +
        ['list', plug.slug].map((part) => part?.replace(/\//g, '')).join('/');
    }

    if (isContentPagePlug(plug) && plug.parentPageSlug) {
      url =
        '/' +
        ['list', plug.parentPageSlug, plug.slug]
          .map((part) => part?.replace(/\//g, ''))
          .join('/');
    }
    if (isFindRetailersPagePlug(plug)) {
      url = '/find-retailers';
    }
    return (
      // TODO fix responsive image
      <GenericLargePlug
        key={key}
        title={title}
        plugIngress={plugIngress}
        image={image}
        desktopImage={desktopImage}
        sizesDesktop={sizesDesktop}
        label={label}
        name={name}
        url={url}
      />
    );
  }
  return (
    <section>
      {title && <Title>{title}</Title>}
      <BigPlugsGridStyled>{plugs?.map(plugsMapper)}</BigPlugsGridStyled>;
    </section>
  );
};
