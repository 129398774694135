import { EmptyProduct } from '../EmptyProduct/EmptyProduct';

export interface EmptyProductsProps {
  pageSize: number;
}

export const EmptyProducts: React.FC<EmptyProductsProps> = ({ pageSize }) =>
  pageSize ? (
    <>
      {Array.from(Array(pageSize))?.map((item, i) => (
        <EmptyProduct key={`EP-${i}`} />
      ))}
    </>
  ) : null;
