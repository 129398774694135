import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/snickers/helpers';

interface OptionProps {
  $oneSize?: boolean;
}

export const Option = styled.div<OptionProps>`
  align-items: center;
  border-left: 1px solid ${colors.gray4};
  padding: 0 20px;
  box-sizing: border-box;
  flex: 1;

  &:nth-of-type(2) {
    display: flex;
    justify-content: flex-end;
  }

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    display: flex;
    justify-content: center;
    padding: 0 64px;
    flex: none;

    &:nth-of-type(2) {
      justify-content: center;
    }
  }

  ${({ $oneSize }) =>
    $oneSize &&
    css`
      display: none;

      a {
        text-decoration: none;
      }
    `}
`;

export const OptionsBarInner = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  height: auto;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    display: flex;
    justify-content: flex-end;
    block-size: 56px;
  }
`;

export const OptionsBarStyled = styled.div`
  position: relative;
  height: auto;
  border-top: 1px solid ${colors.gray4};
  border-bottom: 1px solid ${colors.gray4};
  z-index: 2;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    block-size: 56px;
    padding: 0 4rem;
  }

  .SizeGuideOption a {
    text-decoration: none;
  }

  .ColorPicker {
    inline-size: 100%;
    box-sizing: border-box;

    &.open {
      inset-block-start: -57px;
    }

    @media screen and (min-width: ${breakpoints.desktopSmall}) {
      position: absolute;
      inset-inline-start: 0;
      inset-block-start: -1px;
      block-size: 56px;
      background: ${colors.white};
      border-block-start: 1px solid ${colors.gray4};
      border-block-end: 1px solid ${colors.gray4};
      z-index: -1;
      transition: top 0.3s;
    }

    .ColorPickerInner {
      display: flex;
      margin: 0 auto;
      max-inline-size: 1440px;
      block-size: 100%;
      align-items: center;

      @media screen and (min-width: ${breakpoints.desktopSmall}) {
        padding: 0 20px;
      }

      @media screen and (min-width: ${breakpoints.desktop}) {
        padding: 0 4rem;
      }
    }

    .ColorImages {
      margin: 0 -4px;

      a:first-child {
        margin-inline-start: ${spacing.tinier};
      }
    }
  }

  .ColorImageWrapper {
    display: inline-flex;
    inline-size: 24px;
    block-size: 24px;
    margin: 8px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 0 2px ${colors.white}, 0 0 0 3px ${colors.white};
    border: 1px solid ${colors.gray4};

    &.selected {
      box-shadow: 0 0 0 2px ${colors.white}, 0 0 0 3px ${colors.workwearBlack};
    }

    img {
      inline-size: 100%;
    }
  }

  .ColorOption {
    inline-size: 100%;

    .ColorImageWrapper {
      display: none;

      @media screen and (min-width: ${breakpoints.desktopSmall}) {
        display: inline-flex;
        margin-inline: 0 12px;
      }
    }

    @media screen and (min-width: ${breakpoints.desktopSmall}) {
      inline-size: auto;
    }
  }

  .SizeGuideOption {
    &.OneSize {
      display: none;
    }
  }

  .ColorOption::after,
  .ChooseSizeOption::after {
    background-image: url('/svg/next.svg');
    inline-size: 12px;
    block-size: 12px;
  }

  .ButtonWrapper {
    display: flex;
    inline-size: 100%;
    padding: ${spacing.small} 20px;
    border-block-start: 1px solid ${colors.gray4};

    @media screen and (min-width: ${breakpoints.desktopSmall}) {
      padding: 0;
      border: 0;
      inline-size: auto;
      order: 3;
    }

    .ShoppingList {
      inline-size: 100%;
      block-size: 48px;
      border-radius: 0;
      max-inline-size: 400px;
      margin: 0 auto;
      padding: 0;

      @media screen and (min-width: ${breakpoints.desktopSmall}) {
        block-size: 56px;
        padding: 0 50px;
      }
    }
  }
`;
