import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/snickers/helpers';

interface HeaderStoreItemStyledProps {
  $isFavorite?: boolean;
  $isTransparent?: boolean;
}

export const HeaderStoreItemStyled = styled.button<HeaderStoreItemStyledProps>`
  appearance: none;
  background: transparent;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-block-start: 2px;
  ${({ $isFavorite }) =>
    $isFavorite &&
    css`
      @media screen and (max-width: ${breakpoints.desktop}) {
        margin-inline-end: -12px;
      }
    `}
  min-width: 48px;
  min-height: 48px;

  .Favorite,
  .Cart {
    cursor: pointer;
    position: relative;
    inline-size: 24px;
    block-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    svg {
      inline-size: 24px;
      block-size: 24px;
    }
  }

  .Favorite .Badge {
    inset-block-start: -7px;
    inset-inline-end: -10px;
  }

  .Cart .Badge {
    inset-block-start: -5px;
    inset-inline-end: -8px;
  }
`;
export const IconText = styled.span`
  white-space: nowrap;
  font-size: 10px;
  color: ${colors.workwearBlack};
  letter-spacing: 0.45px;
  margin-block-start: 0.25rem;

  .Transparent & {
    color: ${colors.white};
  }
`;
