import useSWR from 'swr';

import { ParsedProductsApiResponse } from '@hultafors/shared/types';

import { NOTFOUND_PAGE_SIZE } from '@hultafors/snickers/api';
import { useGlobal } from '@hultafors/snickers/hooks';
import { SnickersProduct } from '@hultafors/snickers/types';

import { Grid } from '../Grid/Grid';
import { Products } from '../Products/Products';
import { Section } from '../Section/Section';

interface NotFoundProductsProps {
  products?: string;
}
export const NotFoundProducts: React.FC<NotFoundProductsProps> = ({
  products,
}) => {
  const { global } = useGlobal();
  const { data } = useSWR<ParsedProductsApiResponse<SnickersProduct>>(
    products &&
      `/api/productslist/${23}?productCatalogNodeId=${products}&pageSize=${NOTFOUND_PAGE_SIZE}`,
    {
      revalidateOnMount: true,
    },
  );
  if (!products || !data) {
    return null;
  }
  return (
    <Section>
      <Grid
        columnGap={[{ columnGap: 20 }]}
        columns={[
          { columns: 1 },
          { breakpoint: 'largeMobile', columns: 2 },
          {
            breakpoint: 'desktop',
            columns: data.items.length,
          },
          {
            breakpoint: 'desktopMedium',
            columns: data.items.length,
          },
        ]}
      >
        <Products
          products={data.items}
          badgeText={global?.new || ''}
          rrpLabel={global.rrpLabel || ''}
          rrpIncludingVatLabel={global.rrpIncludingVatLabel || ''}
        />
      </Grid>
    </Section>
  );
};
