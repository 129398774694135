import { useEffect, useRef } from 'react';

import Image from 'next/image';
import { Swiper } from 'swiper';
import {
  A11y,
  Navigation as SwiperNavigation,
  Pagination as SwiperPagination,
} from 'swiper/modules';

import { breakpoints } from '@hultafors/shared/constants';
import { getParttrapImageLoader } from '@hultafors/shared/helpers/parttrap-image-loader/parttrap-image-loader';

import { createProductImageSrc } from '@hultafors/snickers/helpers';
import { useGlobal } from '@hultafors/snickers/hooks';
import { ProductImage } from '@hultafors/snickers/types';

import { HEADER_HEIGHT, OPTIONS_HEIGHT } from '../GlobalStyle/GlobalStyle';

import ChevronSvg from './chevron.svg';
import {
  ImageInner,
  ImgWrapper,
  NavigationButton,
  SwiperContainer,
  SwiperWrapper,
} from './ProductImageCarousel.styled';

require('swiper/css');
require('swiper/css/navigation');

interface ProductImageCarouselProps {
  images?: ProductImage[];
  alt: string;
}

export const ProductImageCarousel: React.FC<ProductImageCarouselProps> = ({
  images = [],
  alt,
}) => {
  const { global } = useGlobal();
  const swiper = useRef<Swiper | null>(null);
  const swiperRef = useRef<HTMLDivElement>(null);

  const sizes = [
    `(min-width: ${breakpoints.desktopLarge}) min(700px, calc(100vh - ${HEADER_HEIGHT} - ${OPTIONS_HEIGHT}))`,
    `(min-width: ${breakpoints.desktopSmall}) min(calc(50vw - 20px), calc(100vh - ${HEADER_HEIGHT}  - ${OPTIONS_HEIGHT}))`,
    '(min-width: 350px) 350px',
    '100vw',
  ].join(', ');

  useEffect(() => {
    if (swiperRef.current && !swiper.current) {
      swiper.current = new Swiper(swiperRef.current, {
        a11y: {
          nextSlideMessage: global.nextLabel || '',
          prevSlideMessage: global.previousLabel || '',
        },
        direction: 'horizontal',
        loop: true,
        modules: [A11y, SwiperNavigation, SwiperPagination],
      });
    }
  }, []);

  function slidePrev() {
    swiper.current?.slidePrev();
  }
  function slideNext() {
    swiper.current?.slideNext();
  }

  function slideMapper(image: ProductImage, index: number) {
    if (!image?.url) {
      return null;
    }
    return (
      <ImgWrapper key={`Carousel-image-${index}`} className="swiper-slide">
        <ImageInner>
          <Image
            src={createProductImageSrc(image.url)}
            sizes={sizes}
            alt={alt}
            fill
            priority={index === 0}
            loader={getParttrapImageLoader()}
          />
        </ImageInner>
      </ImgWrapper>
    );
  }

  if (!images.length) {
    return null;
  }

  return (
    <SwiperContainer className="swiper" ref={swiperRef}>
      <SwiperWrapper className="swiper-wrapper">
        {images.map(slideMapper)}
      </SwiperWrapper>
      <NavigationButton
        aria-label={global.previousLabel || ''}
        onClick={slidePrev}
        $flip
      >
        <ChevronSvg aria-hidden={true} focusable={false} width={13} />
      </NavigationButton>
      <NavigationButton aria-label={global.nextLabel || ''} onClick={slideNext}>
        <ChevronSvg aria-hidden={true} focusable={false} width={13} />
      </NavigationButton>
    </SwiperContainer>
  );
};
