export function createProductImageSrc(
  input: URL | string | undefined | null,
  fallback: any = '',
) {
  if (!input) {
    return fallback;
  }
  const url = new URL(input);
  url.searchParams.set('w', '1440');
  url.searchParams.set('h', '1440');
  url.searchParams.set('bgcolor', 'ffffff');
  url.searchParams.set('format', 'jpg');

  return url.href;
}
