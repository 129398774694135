import { startTransition, useEffect, useId, useRef, useState } from 'react';

import { AnimatePresence, m } from 'framer-motion';
import { AccordionItemState } from 'react-accessible-accordion';

import { RouterLinkProps } from '../RouterLink/RouterLink';

import {
  AccordionItemButtonStyled,
  AccordionItemContent,
  AccordionItemHeadingStyled,
  AccordionItemPanelStyled,
  AccordionItemStyled,
  AccordionLinkStyled,
  ButtonInner,
} from './AccordionItem.styled';

// Hack due to React Accessible Accordion not exporting types
type DivAttributes = React.HTMLAttributes<HTMLDivElement>;
type ID = string | number;
declare type AccordionItemProps = DivAttributes & {
  uuid?: ID;
  activeClassName?: string;
  dangerouslySetExpanded?: boolean;
  label?: React.ReactNode;
  level?: number;
  menu?: boolean;
};

export const AccordionItem: React.FC<AccordionItemProps> = ({
  children,
  label = '',
  level = 1,
  menu,
  uuid,
  ...rest
}) => {
  const uid = useId();
  const [height, setHeight] = useState(0);
  const panelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      panelRef.current !== null &&
      typeof panelRef.current?.scrollHeight === 'number'
    ) {
      const scrollHeight: number = panelRef.current?.scrollHeight;
      const panels = Array.from(panelRef.current?.querySelectorAll('*'));
      startTransition(() => {
        setHeight(
          scrollHeight +
            72 +
            (panels
              ? panels
                  .map((item) => item.scrollHeight)
                  .sort()
                  .shift() || 0
              : 0),
        );
      });
    }
  }, [panelRef]);

  return (
    <AccordionItemStyled {...rest} uuid={uuid ?? uid}>
      <AccordionItemHeadingStyled aria-level={level || rest['aria-level']}>
        <AccordionItemButtonStyled>
          <ButtonInner $menu={menu}>{label || ''}</ButtonInner>
        </AccordionItemButtonStyled>
      </AccordionItemHeadingStyled>
      <AccordionItemPanelStyled $height={height}>
        <AccordionItemState>
          {({ expanded }) => (
            <AnimatePresence>
              {expanded && (
                <m.div
                  initial={{ height: 0 }}
                  animate={{ height: 'auto' }}
                  exit={{ height: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <AccordionItemContent ref={panelRef}>
                    {children}
                  </AccordionItemContent>
                </m.div>
              )}
            </AnimatePresence>
          )}
        </AccordionItemState>
      </AccordionItemPanelStyled>
    </AccordionItemStyled>
  );
};

export const AccordionLink: React.FC<RouterLinkProps> = ({ ...rest }) => (
  <AccordionLinkStyled {...rest} />
);
