import { startTransition, useEffect, useState } from 'react';

import {
  areStringsEqualWhenCleaned,
  cleanTextFromNonLettersAndNumbers,
  hasStringMoreCharsThanMax,
} from '@hultafors/shared/helpers';
import { ProductDetailsValue } from '@hultafors/shared/types';

import { getIsSizeGroupsHiddenForCategory } from '@hultafors/snickers/product-helpers';
import {
  ProductSize,
  ProductSizeList,
  SizeCategory,
} from '@hultafors/snickers/types';

import { OptionsBarButton } from '../OptionsBarButton/OptionsBarButton';
import { SlideIn } from '../SlideIn/SlideIn';

import {
  ContentWrapper,
  NotStandardSize,
  SelectWrapper,
  SizeButton,
  Sizes,
  SizeSelectorHeader,
  SizeSelectorStyled,
  TextAndSelectWrapper,
} from './SizeSelector.styled';

export const SIZE_SELECTOR_DRAWER_ID = 'SizeSelectorDrawer';

export interface SizeSelectorProps {
  sizes?: ProductSizeList[];
  toggleSelectSize(sizeGroup: ProductSizeList, item: ProductSize): void;
  headerText?: string;
  doneText?: string;
  category?: SizeCategory;
  selectedSize?: ProductDetailsValue;
  extraShortText?: string;
  shortText?: string;
  regularText?: string;
  longText?: string;
  extraLongText?: string;
  notStandardSizeText?: string;
  buttonLabel?: string;
  desktopMenu?: boolean;
}

export const SizeSelector: React.FC<SizeSelectorProps> = ({
  sizes = [],
  selectedSize,
  toggleSelectSize,
  category,
  headerText = '',
  notStandardSizeText = '',
  extraShortText = 'Extra Short',
  shortText = 'Short',
  regularText = 'Regular',
  longText = 'Long',
  extraLongText = 'Extra Long',
  buttonLabel,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    startTransition(() => {
      setIsOpen(!isOpen);
    });
  };

  const [selectedSizeGroup, setSelectedSizeGroup] = useState<ProductSizeList>({
    id: '',
    name: '',
    sizes: [],
  });
  const [isSpecialSize, setIsSpecialSize] = useState(false);
  const [isSizeGroupsHiddenForCategory, setIsSizeGroupsHiddenForCategory] =
    useState(false);

  useEffect(() => {
    if (sizes?.length) {
      const isSizeGroupHidden = getIsSizeGroupsHiddenForCategory(category);
      // defaulting to first
      let defaultGroup: ProductSizeList = { sizes: [] };
      if (sizes.length === 1 && sizes[0]) {
        defaultGroup = sizes[0];
      } else {
        const regularGroup = sizes.find(
          (sizeGroup) =>
            cleanTextFromNonLettersAndNumbers(sizeGroup.name || '') ===
            'regular',
        );
        const s = Math.floor(sizes.length / 2);
        const defaultSizeList = sizes[s];
        if (typeof regularGroup !== 'undefined' && regularGroup) {
          defaultGroup = regularGroup;
        }
        if (
          !regularGroup &&
          typeof defaultSizeList !== 'undefined' &&
          defaultSizeList
        ) {
          defaultGroup = defaultSizeList;
        }
      }
      startTransition(() => {
        setSelectedSizeGroup(defaultGroup);
        setIsSizeGroupsHiddenForCategory(isSizeGroupHidden);
      });
    }
  }, [sizes, category]);

  const selectGroup: React.ChangeEventHandler<HTMLSelectElement> = (item) => {
    if (item.target.value) {
      const newlySelectedSizeGroup = sizes?.find(
        (size) => size.id === item.target.value,
      );
      if (newlySelectedSizeGroup) {
        startTransition(() => {
          setSelectedSizeGroup(newlySelectedSizeGroup);
        });
      }
    }
  };

  // const isSizeSelected = (sizeGroupId: undefined | string, item: any) =>
  //   selectedSize &&
  //   sizeGroupId &&
  //   sizeGroupId === selectedSize.id &&
  //   item.value === selectedSize.value
  //     ? 'Selected'
  //     : '';

  const translateHeader = (name: string) => {
    switch (name) {
      case 'Extra Short':
        return extraShortText;
      case 'Short':
        return shortText;
      case 'Regular':
        return regularText;
      case 'Long':
        return longText;
      case 'Extra Long':
        return extraLongText;
      default:
        return '';
    }
  };

  const selectSize = (item: ProductSize) => {
    let sizeGroup: ProductSizeList = selectedSizeGroup;
    if (isSizeGroupsHiddenForCategory) {
      sizeGroup =
        sizes?.find((group) =>
          group.sizes?.some((size: any) =>
            areStringsEqualWhenCleaned(size.value, item.value),
          ),
        ) || sizeGroup;
      toggleSelectSize(sizeGroup, item);
      startTransition(() => {
        setSelectedSizeGroup(sizeGroup);
      });
    } else {
      toggleSelectSize(sizeGroup, item);
    }
    startTransition(() => {
      setIsSpecialSize(!!item.toOrder);
    });
    toggle();
  };

  const getSizeButtons = () => {
    if (isSizeGroupsHiddenForCategory) {
      return getSizeButtonsForHiddenSizeGroups();
    }
    return getSizeButtonsForSelectedSizeGroup();
  };

  const getSizeButtonsForHiddenSizeGroups = () => {
    const areSizeButtonsWide = !!getHasAnySizeGroupWideButtons(sizes);
    return sizes?.filter(Boolean)?.map((sizeGroup, sizeGroupIndex: number) => {
      const { sizes = [] } = sizeGroup;
      return sizes
        .filter(Boolean)
        .map((item, sizeIndex: number) =>
          getSizeButton(
            item,
            `${sizeGroupIndex}-${sizeIndex}`,
            areSizeButtonsWide,
          ),
        );
    });
  };

  const getHasAnySizeGroupWideButtons = (sizeGroups: any) => {
    for (const sizeGroup of sizeGroups) {
      if (getAreSizeButtonsWide(sizeGroup.sizes)) {
        return true;
      }
    }
    return false;
  };

  const getAreSizeButtonsWide = (sizes: any[]) => {
    const NORMAL_SIZE_BUTTON_MAX_CHARS = 5;
    return sizes.some((size) =>
      hasStringMoreCharsThanMax(size.value, NORMAL_SIZE_BUTTON_MAX_CHARS),
    );
  };

  const getSizeButtonsForSelectedSizeGroup = () => {
    const areSizeButtonsWide = getAreSizeButtonsWide(
      selectedSizeGroup.sizes || [],
    );
    return selectedSizeGroup.sizes?.map((item, index) =>
      getSizeButton(item, `${index}`, areSizeButtonsWide),
    );
  };

  const getSizeButton = (
    item: ProductSize,
    index: string,
    areSizeButtonsWide: boolean,
  ) => {
    const onClick = () => selectSize(item);
    return (
      <SizeButton
        key={`Size-${index}`}
        $selected={selectedSize?.text === item.value}
        $wide={areSizeButtonsWide}
        onClick={onClick}
      >
        {`${item.value}${item.toOrder ? '*' : ''}`}
      </SizeButton>
    );
  };

  return (
    <SizeSelectorStyled>
      <OptionsBarButton onClick={toggle} chevron>
        {buttonLabel}
      </OptionsBarButton>

      <SlideIn
        toggle={toggle}
        isOpen={isOpen || false}
        headerText={headerText}
        headerIcon="/assets/gfx/size.svg"
        fromLeft={false}
        id={SIZE_SELECTOR_DRAWER_ID}
      >
        {sizes?.length > 1 && !isSizeGroupsHiddenForCategory && (
          <SizeSelectorHeader>
            <ContentWrapper>
              <TextAndSelectWrapper>
                <SelectWrapper>
                  <select
                    name="sizegroups"
                    onChange={selectGroup}
                    value={selectedSizeGroup.id}
                  >
                    {sizes.map((item, index) => {
                      return (
                        <option key={`Size-Group-${index}`} value={item.id}>
                          {translateHeader(item.name || '')}
                        </option>
                      );
                    })}
                  </select>
                </SelectWrapper>
              </TextAndSelectWrapper>
            </ContentWrapper>
          </SizeSelectorHeader>
        )}

        <Sizes>
          {getSizeButtons()}
          {isSpecialSize && (
            <NotStandardSize>{notStandardSizeText}</NotStandardSize>
          )}
        </Sizes>
      </SlideIn>
    </SizeSelectorStyled>
  );
};
