import { startTransition, useState } from 'react';

import MuxPlayer from '@mux/mux-player-react';
import dynamic from 'next/dynamic';
import Image from 'next/image';

import { colors } from '@hultafors/snickers/helpers';
import { VideoBlockFragment } from '@hultafors/snickers/types';

import {
  Heading,
  MuteButton,
  Outer,
  VideoBlockStyled,
  VideoControls,
} from './VideoBlock.styled';

const H1 = dynamic(() => import('../../../H1/H1').then((module) => module.H1));

const H2 = dynamic(() => import('../../../H2/H2').then((module) => module.H2));

const LinkButton = dynamic(() =>
  import('../../../LinkButton/LinkButton').then((module) => module.LinkButton),
);

export interface VideoBlockProps
  extends Omit<VideoBlockFragment, '__typename' | 'id'> {
  priority?: boolean;
}

export const VideoBlock: React.FC<VideoBlockProps> = ({
  ctaLink,
  ctaText,
  video,
  priority,
  posterImage,
  title,
}) => {
  const [muted, setMuted] = useState(true);

  function toggleSound() {
    startTransition(() => {
      setMuted(!muted);
    });
  }

  let TitleComponent = H2;
  if (priority) {
    TitleComponent = H1;
  }

  return (
    <VideoBlockStyled>
      {title && (
        <Heading as={TitleComponent} bold={false}>
          {title}
        </Heading>
      )}
      {ctaLink && ctaText && (
        <LinkButton large={true} text={ctaText} url={ctaLink} />
      )}
      <Outer>
        {posterImage?.responsiveImage && (
          <Image
            src={posterImage.responsiveImage.src}
            alt={posterImage.alt || ''}
            sizes="100vw"
            fill
            style={{ objectFit: 'cover' }}
            priority={priority}
            placeholder="blur"
            blurDataURL={posterImage.responsiveImage.base64 || ''}
          />
        )}
      </Outer>
      {video?.video?.muxPlaybackId && (
        <Outer>
          <MuxPlayer
            playbackId={video.video.muxPlaybackId}
            placeholder={video.blurUpThumb ?? undefined}
            streamType="on-demand"
            autoPlay="muted"
            muted={muted}
            title={title ?? video.title ?? video.alt ?? undefined}
            accent-color={colors.workwearYellow}
            style={{ height: '100%', width: '100%' }}
          />
          <VideoControls>
            <MuteButton $isMuted={muted} onClick={toggleSound} />
          </VideoControls>
        </Outer>
      )}
    </VideoBlockStyled>
  );
};
