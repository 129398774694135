import dynamic from 'next/dynamic';

import { Store } from '@hultafors/shared/types';

import { useGlobal } from '@hultafors/snickers/hooks';

import {
  FindRetailersListStyled,
  StoreLabel,
  StoreSubTitle,
  StoreTitle,
} from './FindRetailersList.styled';

const Accordion = dynamic(() =>
  import('../Accordion/Accordion').then((mod) => mod.Accordion),
);
const AccordionItem = dynamic(() =>
  import('../AccordionItem/AccordionItem').then((mod) => mod.AccordionItem),
);
const StoreItem = dynamic(() =>
  import('../StoreItem/StoreItem').then((mod) => mod.StoreItem),
);

export interface FindRetailersListProps {
  stores: Store[];
  selectItem?(id: string): void;
  selectedItem?: Store;
  viewOnMap: string;
  storeAlsoSells: string;
  visitWebsite: string;
}

export const FindRetailersList: React.FC<FindRetailersListProps> = ({
  stores,
  selectItem,
  selectedItem,
  viewOnMap,
  storeAlsoSells,
  visitWebsite,
}) => {
  const keyPrefix = 'Store';
  const { settings } = useGlobal();
  const storeMapper = (item: Store) => {
    return (
      <AccordionItem
        key={`Store-${item.id}`}
        label={
          <StoreLabel>
            <StoreTitle>{item.data.companyName}</StoreTitle>
            <StoreSubTitle>{item.data.city}</StoreSubTitle>
          </StoreLabel>
        }
      >
        <StoreItem
          coordinates={{
            lat: parseFloat(
              item.data.latitude || `${settings.defaultCoords?.latitude}` || '',
            ),
            lng: parseFloat(
              item.data.longitude ||
                `${settings.defaultCoords?.longitude}` ||
                '',
            ),
          }}
          label={item.data.companyName || ''}
          subLabel={item.data.city || ''}
          key={`${keyPrefix}-${item.id}`}
          keyPrefix={keyPrefix}
          id={item.id}
          address={item.data.address1 || ''}
          zipCode={item.data.zipCode || ''}
          city={item.data.city || ''}
          country={item.data.country || ''}
          mapLink={item.mapLink}
          view_on_map={viewOnMap}
          emailAddress={item.data.emailAddress || ''}
          phone={item.data.phone || ''}
          storeSells={item.storeSells}
          this_store_also_sells={storeAlsoSells}
          website={item.data.webSite || ''}
          visit_web_site={visitWebsite}
        />
      </AccordionItem>
    );
  };
  return (
    <FindRetailersListStyled>
      {stores.length > 0 && (
        <Accordion allowZeroExpanded>{stores.map(storeMapper)}</Accordion>
      )}
    </FindRetailersListStyled>
  );
};
