import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { fontSizes, fontWeights, spacing } from '@hultafors/snickers/helpers';

export type H4BottomSpacing = 'small' | 'tiny' | 'tinier';

interface H4StyledProps {
  $center?: boolean;
  $large?: boolean;
  $underline?: boolean;
  $bottomSpacing?: H4BottomSpacing;
}

export const H4Styled = styled.h4<H4StyledProps>`
  text-align: ${({ $center }) => ($center ? 'center' : 'inherit')};
  font-size: ${({ $large }) => ($large ? fontSizes.megabody : '16px')};
  font-weight: ${fontWeights.medium};
  text-decoration: ${({ $underline }) => ($underline ? 'underline' : 'none')};

  ${({ $bottomSpacing }) =>
    $bottomSpacing === 'small' &&
    css`
      margin-block-end: ${spacing.tiny};
    `}
  @media screen and (min-width: ${breakpoints.mobileMax}) {
    text-decoration: none;
  }
`;
