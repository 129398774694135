import {
  InterviewPagePlugFragment,
  MoreArticlesBlockFragment,
  PersonFragment,
} from '@hultafors/snickers/types';

import { RelatedArticles } from '../../../RelatedArticles/RelatedArticles';
import { Section } from '../../../Section/Section';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MoreArticlesBlockProps
  extends Omit<MoreArticlesBlockFragment, '__typename' | 'id'> {}

export const MoreArticlesBlock: React.FC<MoreArticlesBlockProps> = ({
  header,
  storiesLinkText,
  storiesLinkUrl,
  plug,
  interviewPagePlug,
}) => {
  const interviewPlug: InterviewPagePlugFragment[] = interviewPagePlug?.map(
    (plug) => {
      const interViewObject: PersonFragment =
        (plug?.contentArea?.filter(
          (item) => item.__typename === 'PersonRecord',
        )[0] as PersonFragment) ||
        (plug?.contentArea.filter(
          (item) => item.__typename === 'PersonRecord',
        )[0] as PersonFragment);

      return {
        id: plug?.id,
        image: plug?.desktopTeaserImage || plug?.seo?.image,
        interviewLabel: interViewObject?.interviewLabel || null,
        interviewName: interViewObject?.name || null,
        pageLink: {
          __typename: plug?.__typename,
          id: plug?.id,
          slug: plug?.slug,
        },
        title:
          plug?.teaserTitle ||
          plug?.seo?.title?.replace(/\s*\?\s+Snickers Workwear\s*$/i, ''),
      } as InterviewPagePlugFragment;
    },
  );

  // Temporary in order to not break any old functionality
  function checkStoriesUrl() {
    let url = '';
    if (storiesLinkUrl) {
      url = storiesLinkUrl;
    }
    if (!storiesLinkUrl && interviewPagePlug[0]?.parentPageSlug) {
      url = '/' + interviewPagePlug[0].parentPageSlug.replace(/\//g, '');
    }

    return url;
  }

  return (
    <Section noTop largePaddingTop borderTop>
      <RelatedArticles
        title={header || ''}
        parentPageSlug={checkStoriesUrl()}
        readMoreLabel={storiesLinkText || ''}
        className="Col-2"
        article={interviewPlug.length > 0 ? interviewPlug : plug}
      />
    </Section>
  );
};
