import { ProductModel, RawProductModel } from '@hultafors/snickers/types';

import { getFirstImage } from './getFirstImage';

/**
 * @desc Transforms model object to simpler version
 * @return cart (array)
 */
export const transformModel = (
  model: RawProductModel,
  amount: number | string,
): ProductModel => {
  return {
    amount: parseInt(`${amount}`),
    attributes: model.attributes,
    id: parseInt(model.productId, 10),
    image: getFirstImage(model),
    name: model.name,
    sku: model.sku,
  };
};
