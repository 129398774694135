import { isMorePages } from '@hultafors/shared/helpers';
import { ListApiPaging } from '@hultafors/shared/types';

import { Button } from '../Button/Button';
import { Gauge } from '../Gauge/Gauge';

import { PagerStyled } from './Pager.styled';

export interface PagerProps {
  buttonText: string;
  ofText: string;
  productsText: string;
  onClick: React.MouseEventHandler;
  pagerData?: ListApiPaging;
  loading?: boolean;
}

export const Pager: React.FC<PagerProps> = ({
  buttonText,
  ofText,
  productsText,
  pagerData,
  onClick,
  loading,
}) => {
  if (!pagerData) {
    return null;
  }
  const { itemCount, pageNumber, pageCount, pageSize } = pagerData;
  if (!itemCount || pageNumber >= pageCount) {
    return null;
  }

  const resultCount =
    pageNumber * pageSize > itemCount ? itemCount : pageNumber * pageSize;

  const soFar = Math.round((resultCount / itemCount) * 100);
  const toGo = 100 - soFar;

  return (
    <PagerStyled>
      <div className="PagerInner">
        <div className="Pager">
          {resultCount} {ofText} {itemCount} {productsText}
        </div>
        <Gauge soFar={soFar} toGo={toGo} />

        {isMorePages(pagerData) && (
          <Button onClick={onClick} grey isLoading={loading}>
            {buttonText}
          </Button>
        )}
      </div>
    </PagerStyled>
  );
};
