import dynamic from 'next/dynamic';
import Image from 'next/image';

import { ImageFragment } from '@hultafors/snickers/types';

import { ImageBlockStyled, ImageContainer } from './ImageBlock.styled';

const Grid = dynamic(() =>
  import('../Grid/Grid').then((module) => module.Grid),
);
const GridChild = dynamic(() =>
  import('../GridChild/GridChild').then((module) => module.GridChild),
);

export interface ImageBlockProps {
  image?: ImageFragment | null;
  altText?: string;
}

export const ImageBlock: React.FC<ImageBlockProps> = ({ image, altText }) => {
  if (!image?.responsiveImage?.src) {
    return null;
  }
  const sizes = [
    '(min-width: 1440px) 1200px',
    '(min-width: 1024px) 82vw',
    '100vw',
  ].join(', ');
  return (
    <ImageBlockStyled>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 5 },
            { breakpoint: 'mobileMax', columns: 12 },
            { breakpoint: 'desktop', columns: 10, start: 2 },
          ]}
        >
          <ImageContainer>
            <Image
              src={image.responsiveImage?.src || ''}
              alt={altText ?? image.alt ?? ''}
              blurDataURL={image.responsiveImage?.base64 ?? undefined}
              placeholder={image.responsiveImage?.base64 ? 'blur' : 'empty'}
              fill
              sizes={sizes}
            />
          </ImageContainer>
        </GridChild>
      </Grid>
    </ImageBlockStyled>
  );
};
