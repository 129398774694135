import styled, { css, CSSProperties, keyframes } from 'styled-components';

import {
  colors,
  fontFamilies,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/snickers/helpers';

const rotate = keyframes`
  from { transform: translate(-50%, -50%) rotate(0deg); opacity: 1; }
  to   { transform: translate(-50%, -50%) rotate(360deg); opacity: 1; }
`;

const appear = keyframes`
    0% { transform: translate(-50%, 20%) scale(0.3); opacity: 0; }
    85%   { transform: translate(-50%, -60%) scale(1); opacity: 1; }
    100%   { transform: translate(-50%, -50%) scale(1); opacity: 1; }
`;

interface ButtonStyledProps {
  $letterSpacing?: CSSProperties['letterSpacing'];
  $uppercase?: boolean;
  $iconNext?: boolean;
  $small?: boolean;
  $isLoading?: boolean;
  $yellow?: boolean;
  $grey?: boolean;
  $ghost?: boolean;
}

export const ButtonStyled = styled.button<ButtonStyledProps>`
  appearance: none;
  width: ${({ $small }) => ($small ? 'auto' : '100%')};
  display: block;
  padding-block: ${({ $small }) => ($small ? spacing.tiny : spacing.small)};
  padding-inline: ${spacing.small};
  box-sizing: border-box;
  font-family: ${fontFamilies.mikro};
  font-size: ${fontSizes.m};
  line-height: ${lineHeights.m};
  color: ${colors.white};
  letter-spacing: ${({ $letterSpacing }) => $letterSpacing};
  border: 1px solid ${colors.workwearBlack};
  background-color: ${colors.workwearBlack};
  border-radius: 2px;
  cursor: pointer;
  transition: 0.2s;
  text-transform: ${({ $uppercase }) => ($uppercase ? 'uppercase' : 'initial')};

  @media screen and (hover: none) {
    min-block-size: 48px;
  }

  &:hover {
    background-color: ${colors.gray1};
    border-color: ${colors.gray1};
  }

  ${({ $grey }) =>
    $grey &&
    css`
      background-color: ${colors.gray4};
      border-color: ${colors.gray4};
      color: ${colors.black};

      &:hover {
        background-color: ${colors.grayBtnHover};
        border-color: ${colors.grayBtnHover};
      }
    `}

  ${({ $yellow }) =>
    $yellow &&
    css`
      background-color: ${colors.workwearYellow};
      border-color: ${colors.workwearYellow};
      color: ${colors.workwearBlack};
      border-radius: 0;

      &:hover {
        background-color: ${colors.workwearYellow2};
        border-color: ${colors.workwearYellow2};
      }
    `}

  ${({ $ghost }) =>
    $ghost &&
    css`
      background-color: transparent;
      border-color: ${colors.workwearBlack};
      color: ${colors.workwearBlack};

      &:hover {
        background-color: ${colors.workwearBlack};
        color: ${colors.white};
      }
    `}



  &.Icon {
    position: relative;
    padding: 0 50px;

    &::after {
      content: '';
      position: absolute;
      display: block;
      inline-size: 16px;
      block-size: 16px;
      inset-inline-end: ${spacing.small};
      inset-block-start: 50%;
      transform: translateY(-50%);
      background-image: url('/svg/basket.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  ${({ $iconNext }) =>
    $iconNext &&
    css`
      position: relative;
      padding: 0 ${spacing.large};

      svg {
        position: absolute;
        display: block;
        inline-size: 16px;
        block-size: 16px;
        inset-inline-end: ${spacing.small};
        inset-block-start: 50%;
        transform: translateY(-50%);
      }
    `};

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      position: relative;
      font-size: 0;
      transition: all 0.3s;

      &::after {
        content: '';
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: 50%;
        inline-size: 24px;
        block-size: 24px;
        background-image: url('/svg/loading-circle.svg');
        background-repeat: no-repeat;
        background-position: center;
        animation: ${rotate} 1s linear;
        animation-iteration-count: infinite;
        transform-origin: center;
      }
    `};

  &.AddingInCart {
    position: relative;
    transition: all 0.3s;
    overflow: hidden;
    color: ${colors.workwearYellow};

    &:hover {
      background-color: ${colors.workwearYellow};
    }

    &::before {
      content: '';
      position: absolute;
      inset-block-start: 50%;
      inset-inline-start: 50%;
      inline-size: 24px;
      block-size: 24px;
      background-image: url('/svg/loading-circle.svg');
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0;
      animation: ${rotate} 1s linear;
      animation-iteration-count: 1;
      transform-origin: center;
    }

    &::after {
      content: '';
      position: absolute;
      inset-block-start: 50%;
      inset-inline-start: 50%;
      inline-size: 32px;
      block-size: 32px;
      background-image: url('/svg/check-mark.svg');
      background-repeat: no-repeat;
      background-position: center;
      transform: translate(-50%, 40%) scale(0.6);
      opacity: 0;
      animation: ${appear} 0.4s ease-out;
      animation-iteration-count: 1;
      animation-delay: 0.9s;
      animation-fill-mode: forwards;
      transform-origin: center;
    }
  }

  &:disabled {
    cursor: not-allowed;
    border: 0;

    /* color: ${colors.gray1}; */
    transition: 0.2s;
    opacity: 0.4;

    &:hover {
      background-color: ${colors.workwearBlack};
    }

    svg {
      opacity: 0.3;
    }
  }
`;
