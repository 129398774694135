import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';
import { buttonReset } from '@hultafors/shared/helpers';

import { colors, fontSizes, spacing } from '@hultafors/snickers/helpers';

export const ImageInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  img {
    mix-blend-mode: multiply;
    object-fit: contain;
  }
`;

export const ImgWrapper = styled.div`
  display: inline-flex !important;
  justify-content: center;
  height: 100%;
  outline: none;
  background-color: ${colors.gray5};
  padding-block: 20px;
  box-sizing: border-box;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    padding-block: 48px;
  }

  .SingleItem & {
    display: flex;
    inline-size: 100%;
    block-size: 100%;
    justify-content: center;
    background-color: ${colors.gray5};

    @media screen and (min-width: ${breakpoints.desktopSmall}) {
      block-size: 100%;
    }
  }
`;

export const SwiperContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const SwiperWrapper = styled.div`
  aspect-ratio: 1 / 1;
  max-height: 350px;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    aspect-ratio: inherit;
    max-block-size: inherit;
  }
`;

export const SwiperSlide = styled.div`
  position: relative;

  img {
    object-fit: contain;
  }
`;

export const Pagination = styled.div`
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
  font-size: ${fontSizes.s};
  color: ${colors.gray2};
  line-height: 32px;
  display: flex;
  justify-content: center;
  gap: ${spacing.tinier};
  padding-block: ${spacing.tinier};

  .swiper-pagination-bullet {
    display: block;
    border-radius: 100%;
    inline-size: 8px;
    block-size: 8px;
    background-color: ${colors.gray3};
    transition: all 0.2s;
  }

  .swiper-pagination-bullet-active {
    background-color: ${colors.gray1};
  }
`;

export const NavigationButton = styled.button<{ $flip?: boolean }>`
  ${buttonReset};
  display: none;
  position: absolute;
  top: calc(50% - 24px);
  right: 0;
  cursor: pointer;
  height: 48px;
  width: 48px;
  align-items: center;
  justify-content: center;
  z-index: 1;

  ${({ $flip }) =>
    $flip &&
    css`
      left: 0;

      svg {
        transform: rotate(180deg);
      }
    `};
  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    display: flex;
  }
`;
