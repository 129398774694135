import {
  WorkwearGuideFilter,
  WorkwearGuideSelection,
  WorkwearGuideSelectionSingle,
  WorkwearGuideStep,
} from '@hultafors/snickers/types';

import { Button } from '../Button/Button';
import { Center } from '../Center/Center';
import { GuideHero } from '../GuideHero/GuideHero';
import { Loader } from '../Loader/Loader';
import { WorkwearGuideBottomSection } from '../WorkwearGuideBottomSection/WorkwearGuideBottomSection';
import { WorkwearGuideContainer } from '../WorkwearGuideContainer/WorkwearGuideContainer';
import { WorkwearGuideInner } from '../WorkwearGuideInner/WorkwearGuideInner';

import {
  Container,
  RoundBox,
  RoundBoxInput,
  RoundBoxLabel,
} from './WorkwearGuideRoundBoxes.styled';

export interface WorkwearGuideRoundBoxesProps {
  changeStep(stepNumber: number): void;
  selectFilter(
    category: WorkwearGuideSelectionSingle,
    item: WorkwearGuideFilter,
  ): void;
  currentStep: WorkwearGuideStep;
  numberOfSteps: number;
  selection: WorkwearGuideSelection;
  filters: WorkwearGuideFilter[];
  title: string;
  backText?: string;
  nextText?: string;
  loading?: boolean;
}

/* For radio style selection */

export const WorkwearGuideRoundBoxes: React.FC<
  WorkwearGuideRoundBoxesProps
> = ({
  selection,
  changeStep,
  selectFilter,
  currentStep,
  filters,
  numberOfSteps,
  backText = '',
  nextText = '',
  title = '',
  loading = true,
}) => {
  const stepName = currentStep.name as WorkwearGuideSelectionSingle;
  const isSelected = (item: WorkwearGuideFilter): boolean =>
    item.id === selection[stepName]?.id;

  const filterMapper = (item: WorkwearGuideFilter) => {
    const onChange = () => selectFilter(stepName, item);
    return (
      <RoundBox key={`RoundBoxes-${item.id}`} $selected={isSelected(item)}>
        <RoundBoxInput
          id={`RoundBoxes-${item.id}`}
          name={item.description}
          type="checkbox"
          value={selection[stepName]?.id}
          checked={isSelected(item)}
          onChange={onChange}
        />
        <RoundBoxLabel>{item.description}</RoundBoxLabel>
      </RoundBox>
    );
  };

  const onClick = () => changeStep(currentStep.number + 1);

  return (
    <WorkwearGuideContainer>
      {loading ? (
        <Center>
          <Loader small />
        </Center>
      ) : (
        <WorkwearGuideInner>
          <GuideHero
            backText={backText}
            currentStep={currentStep.number}
            numberOfSteps={numberOfSteps}
            changeStep={changeStep}
            title={title}
            guideType="workwear"
          />

          <Container>{filters.map(filterMapper)}</Container>
        </WorkwearGuideInner>
      )}

      <WorkwearGuideBottomSection>
        <Button iconNext onClick={onClick} disabled={loading}>
          {nextText}
        </Button>
      </WorkwearGuideBottomSection>
    </WorkwearGuideContainer>
  );
};
