import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
} from '@hultafors/snickers/helpers';

interface ProductDetailsStyledProps {
  $large?: boolean;
  $noBottomMargin?: boolean;
  $card?: boolean;
}
export const ProductDetailsStyled = styled.div<ProductDetailsStyledProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block-end: ${({ $noBottomMargin }) => ($noBottomMargin ? 0 : '10px')};

  ${({ $card }) =>
    $card &&
    css`
      position: relative;
    `}

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-block-end: 8px;
  }

  span {
    font-family: ${fontFamilies.mikro};
    font-size: ${fontSizes.mini2mobile};
    font-weight: ${fontWeights.medium};
    color: ${colors.gray2};
    letter-spacing: 0.5px;

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      font-size: ${({ $large }) =>
        $large ? fontSizes.mini1 : fontSizes.mini2};
    }
  }

  &.mobileProductInfo span {
    font-size: 12px;
  }

  span:last-of-type:not(:nth-of-type(1)) {
    margin-inline-start: 15px;
  }

  .Favorite {
    block-size: 24px;
    inline-size: 24px;

    svg {
      block-size: 24px;
      inline-size: 24px;
    }
  }
`;
