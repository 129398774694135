import Link from 'next/link';

import { LinkBoxStyled } from './LinkBox.styled';

export interface LinkBoxProps {
  url: string;
  label?: string;
}

export const LinkBox: React.FC<LinkBoxProps> = ({ url, label }) => {
  return url ? (
    <LinkBoxStyled>
      <Link href={url}>
        <div className="LinkBoxInner">
          <span className="LinkBoxLabel">{label}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="Arrow"
            width="39"
            height="18"
          >
            <g fill="#232120" fillRule="evenodd">
              <path
                className="Line"
                d="M2 7.97539116h28.9804515L29 9.9753912H0z"
              />
              <path d="M29 16.07106781l-1.41421356 1.41421356h2.82842712L38.89949494 9l-1.41421357-1.41421356-7.0710678-7.07106781h-2.82842713L29 1.92893219 36.07106781 9 29 16.07106781z" />
            </g>
          </svg>
        </div>
      </Link>
    </LinkBoxStyled>
  ) : null;
};
