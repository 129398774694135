import styled, { css } from 'styled-components';

import { colors } from '@hultafors/snickers/helpers';

export const Container = styled.div<{ $result?: boolean }>`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  background: ${colors.white};
  box-shadow: 0 0 3px 0 ${colors.boxshadowTransparent};
  z-index: 2;

  ${({ $result }) =>
    $result &&
    css`
      justify-content: center;
      background-color: ${colors.gray5};
      border-top: 1px solid ${colors.gray4};
      padding: 30px 20px 40px;
      box-shadow: none;
      flex-direction: column;
    `}

  button {
    block-size: 48px;
  }
`;
