import { FC } from 'react';

import {
  ProductContentPlugRecord,
  ProductContentPlugsBlockFragment,
} from '@hultafors/snickers/types';

import { Grid } from '../../../Grid/Grid';
import { Section } from '../../../Section/Section';
import { SmallContentPlug } from '../../../SmallContentPlug/SmallContentPlug';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ProductContentPlugsBlockProps
  extends Omit<ProductContentPlugsBlockFragment, '__typename' | 'id'> {}

export const ProductContentPlugsBlock: FC<ProductContentPlugsBlockProps> = ({
  plugs,
}) => {
  return (
    <Section noTop>
      <Grid
        columnGap={[
          { columnGap: 15 },
          { breakpoint: 'desktop', columnGap: 30 },
        ]}
        columns={[
          { columns: 1 },
          { breakpoint: 'largeMobile', columns: 2 },
          { breakpoint: 'desktopMedium', columns: 4 },
        ]}
      >
        {plugs?.map((plug) => {
          const { id, title, ingress, image, link, ctaButtonText } =
            plug as ProductContentPlugRecord;
          return (
            <SmallContentPlug
              key={id}
              title={title || ''}
              text={ingress || ''}
              image={image || undefined}
              url={link || ''}
              CTAText={ctaButtonText || ''}
              className="Col-4"
            />
          );
        })}
      </Grid>
    </Section>
  );
};
