import {
  KeyboardEvent,
  MouseEvent,
  startTransition,
  useEffect,
  useRef,
  useState,
} from 'react';

import { DropdownStyled } from './Dropdown.styled';

export interface DropdownProps {
  label: string;
  listItems: string[];
  onSelectItem(...args: any[]): unknown;
  reset?: boolean;
  selectedIndex?: number;
  className?: string;
  dark?: boolean;
  disabled?: boolean;
}

export const Dropdown: React.FC<DropdownProps> = ({
  listItems = [],
  selectedIndex,
  dark,
  disabled,
  label,
  onSelectItem,
  reset,
  className,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedListItem, setSelectedListItem] = useState<string>('');
  const [focusedListItem, setFocusedListItem] = useState<number>(-1);
  const dropdownList = useRef<HTMLUListElement>(null);

  const toggleListVisibilityClick = () => {
    startTransition(() => {
      setIsOpen(!isOpen);
    });
  };

  const closeList = () => {
    startTransition(() => {
      setIsOpen(false);
    });
  };

  const toggleListVisibility = (event: KeyboardEvent) => {
    switch (event.key) {
      case ' ':
      case 'Enter':
        startTransition(() => {
          setIsOpen(!isOpen);
        });
        break;
      case 'Escape':
        closeList();
        break;
      case 'ArrowDown':
        if (isOpen) {
          focusNextListItem('ArrowDown');
        }
        break;
      case 'UpArrow':
        if (isOpen) {
          focusNextListItem('ArrowUp');
        }
        break;
      default:
        break;
    }
  };

  const selectListItemOnKeyDown = (event: KeyboardEvent<HTMLLIElement>) => {
    switch (event.key) {
      case 'Enter':
        setSelectedListItemFromEvent(event);
        closeList();
        return;
      case 'ArrowDown':
        focusNextListItem('ArrowDown');
        return;
      case 'ArrowUp':
        focusNextListItem('ArrowUp');
        return;
      case 'Escape':
        closeList();
        return;

      default:
        return;
    }
  };

  const selectListItemOnClick = (event: MouseEvent<HTMLLIElement>) => {
    setSelectedListItemFromEvent(event);
    closeList();
  };

  const setSelectedListItemFromEvent = (
    event: MouseEvent<HTMLLIElement> | KeyboardEvent<HTMLLIElement>,
  ) => {
    startTransition(() => {
      setSelectedListItem(event.currentTarget?.innerText);
    });
    onSelectItem(event.currentTarget.id);
  };

  const focusNextListItem = (direction: 'ArrowDown' | 'ArrowUp') => {
    let activeListItem = focusedListItem;
    if (direction === 'ArrowDown') {
      if (activeListItem === null) {
        activeListItem = 0;
      } else {
        const currentActiveElementIsNotLastItem =
          focusedListItem < listItems.length - 1;

        if (currentActiveElementIsNotLastItem) {
          activeListItem += 1;
        }
      }
    } else if (direction === 'ArrowUp') {
      if (activeListItem === null) {
        activeListItem = listItems.length - 1;
      } else {
        const currentActiveElementIsNotFirstItem = focusedListItem > 0;

        if (currentActiveElementIsNotFirstItem) {
          activeListItem -= 1;
        }
      }
    }
    startTransition(() => {
      setFocusedListItem(activeListItem);
    });
    // TODO: Fix dropdown item focus
    // this.dropdownList.current?.querySelector('.focus')?.focus()
  };

  useEffect(() => {
    if (reset) {
      setSelectedListItem('');
    } else {
      if (
        typeof selectedIndex === 'number' &&
        selectedIndex >= 0 &&
        listItems?.[selectedIndex]
      ) {
        setSelectedListItem(listItems?.[selectedIndex] || '');
      }
    }
  }, [reset]);

  useEffect(() => {
    if (typeof selectedIndex === 'number' && !!listItems?.[selectedIndex]) {
      setSelectedListItem(listItems?.[selectedIndex] || '');
    }
  }, [selectedIndex]);

  return (
    <DropdownStyled className={className} $dark={dark} $disabled={disabled}>
      <button
        aria-labelledby="dropdown-label"
        id="dropdown__selected"
        className={isOpen ? 'DropdownLabel isOpen' : 'DropdownLabel'}
        tabIndex={0}
        onClick={toggleListVisibilityClick}
        onKeyDown={toggleListVisibility}
      >
        {selectedListItem ? selectedListItem : label}
      </button>
      <ul
        className={isOpen ? 'DropdownList open' : 'DropdownList'}
        ref={dropdownList}
      >
        {listItems?.map((item, i) => {
          return (
            <li
              className={
                i === focusedListItem
                  ? 'DropdownListItem focus'
                  : 'DropdownListItem'
              }
              key={'Option-' + i}
              id={`${i}`}
              onClick={selectListItemOnClick}
              onKeyDown={selectListItemOnKeyDown}
              role="none"
            >
              {item}
            </li>
          );
        })}
      </ul>
    </DropdownStyled>
  );
};
