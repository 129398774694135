import styled, { css, CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/snickers/helpers';

export const ImageTextBlockStyled = styled.div`
  display: grid;
  place-items: stretch stretch;
  grid-auto-flow: row;
  height: auto;
  width: 100%;
  max-width: 1400px;
  margin-inline: auto;
  padding-inline: 20px;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-inline: 0;
  }
`;

interface InnerContainerProps {
  $alignMediaLeft?: boolean;
}

export const Inner = styled.div<InnerContainerProps>`
  display: flex;
  flex-direction: ${({ $alignMediaLeft }) =>
    $alignMediaLeft ? 'column-reverse' : 'column'};
  align-items: center;
  grid-column: span 4;
  justify-self: stretch;

  > div {
    aspect-ratio: 3 / 3;

    @media screen and (width >= 450px) {
      aspect-ratio: 5 / 4;
    }

    @media screen and (min-width: ${breakpoints.largeMobile}) {
      aspect-ratio: 16 / 8;
    }

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      aspect-ratio: 5 / 4;
    }
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    grid-column: span 12;
    flex-direction: ${({ $alignMediaLeft }) =>
      $alignMediaLeft ? 'row-reverse' : 'row'};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    grid-column: 2 / span 10;
  }
`;

interface TextContainerProps {
  $backgroundColor?: CSSProperties['backgroundColor'];
  $alignContentCenter?: boolean;
  $alignContentRight?: boolean;
}

export const TextContainer = styled.div<TextContainerProps>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  & :first-child {
    margin-block-start: 1.75rem;
  }

  & :last-child {
    margin-block-end: 1.75rem;
  }

  gap: 2rem;

  > * {
    max-inline-size: 90%;

    @media screen and (min-width: ${breakpoints.desktopSmall}) {
      max-inline-size: 85%;
    }
  }

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    padding-inline: 0.5;
  }

  background-color: ${({ $backgroundColor }) =>
    $backgroundColor ?? colors.gray4};

  ${({ $alignContentCenter }) =>
    $alignContentCenter &&
    css`
      text-align: center;
    `}

  ${({ $alignContentRight }) =>
    $alignContentRight &&
    css`
      text-align: end;
    `}

  p {
    line-height: 24px;
    margin: 0;
  }

  p + p {
    margin-block-start: ${spacing.tiny};
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    font-size: 1rem;
  }

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    font-size: initial;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    font-size: initial;
  }
`;

export const TitleWrapper = styled.div<{
  $alignContentCenter?: boolean;
  $alignContentRight?: boolean;
}>`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${({ $alignContentCenter }) =>
    $alignContentCenter &&
    css`
      justify-content: center;
    `}
  ${({ $alignContentRight }) =>
    $alignContentRight &&
    css`
      justify-content: flex-end;
    `};
`;

export const Title = styled.h2`
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.header2};
  line-height: ${fontSizes.header2};
  margin: 0;
`;

export const MediaWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  --dialog: none; /* Hide the error dialog */
  --media-object-fit: cover;
  --controls: none;

  > img {
    object-fit: cover;
    block-size: 100%;
    inline-size: 100%;
  }
`;

export const LinkWrapper = styled.div<{
  $textColor?: string;
  $linkColor?: string;
  $alignContentCenter?: boolean;
  $alignContentRight?: boolean;
}>`
  width: 90%;
  display: flex;

  ${({ $alignContentCenter }) =>
    $alignContentCenter &&
    css`
      justify-content: center;
    `}

  ${({ $alignContentRight }) =>
    $alignContentRight &&
    css`
      justify-content: flex-end;
    `}

  > a {
    ${({ $linkColor }) =>
      $linkColor &&
      css`
        background-color: ${$linkColor};

        :hover {
          background-color: ${$linkColor};
          filter: brightness(0.8);
        }
      `}

    ${({ $textColor }) =>
      $textColor &&
      css`
        color: ${$textColor};
      `}
  }
`;
