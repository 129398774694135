import { useGlobal } from '@hultafors/snickers/hooks';
import { SIZE_GUIDE_STEPS } from '@hultafors/snickers/product-helpers';
import { FileLinkFragment } from '@hultafors/snickers/types';

import { ArrowSmall } from '../../ArrowSmall/ArrowSmall';
import { GuideHero } from '../../GuideHero/GuideHero';
import { Loader } from '../../Loader/Loader';
import { Paragraph } from '../../Paragraph/Paragraph';
import { ChangeStepHandler } from '../SizeGuide';
import { SizeGuideStyled } from '../SizeGuide.styled';

import { IntroSelection, IntroTextButton } from './IntroScreen.styled';

export interface IntroScreenProps {
  changeStep: ChangeStepHandler;
  title?: string;
  introText?: string;
  backText?: string;
  knowWaistLegHeader?: string;
  haveTrousersHeader?: string;
  otherBrandHeader?: string;
  haveJeansHeader?: string;
  howToMeasureHeader?: string;
  sizeChartHeader?: string;
  sizeChart?: FileLinkFragment | null;
  isLoading?: boolean;
}

export const IntroScreen: React.FC<IntroScreenProps> = ({
  sizeChart,
  title,
  introText,
  backText,
  changeStep,
  isLoading,
  knowWaistLegHeader,
  haveTrousersHeader,
  otherBrandHeader,
  haveJeansHeader,
  sizeChartHeader,
}) => {
  const { settings } = useGlobal();

  const sizeGuideUrl =
    sizeChart?.url ??
    'https://www.datocms-assets.com/57215/1637595446-awsizecharten.pdf';

  const showAllItems = 'us' !== settings.market;

  return (
    <SizeGuideStyled>
      <GuideHero
        title={title}
        introText={introText}
        isBig
        backText={backText}
        currentStep={SIZE_GUIDE_STEPS['INTRO'] || 0}
        changeStep={changeStep}
        guideType="size"
      />
      {isLoading ? (
        <div>
          <Loader small />
        </div>
      ) : (
        <IntroSelection>
          <IntroTextButton
            onClick={() => changeStep(SIZE_GUIDE_STEPS['MEASURE_BODY'])}
          >
            {knowWaistLegHeader}
            <ArrowSmall />
          </IntroTextButton>
          <IntroTextButton
            onClick={() => changeStep(SIZE_GUIDE_STEPS['MEASURE_TROUSERS'])}
          >
            {haveTrousersHeader}
            <ArrowSmall />
          </IntroTextButton>
          {showAllItems && (
            <IntroTextButton
              onClick={() => changeStep(SIZE_GUIDE_STEPS['OTHER_BRAND'])}
            >
              {otherBrandHeader}
              <ArrowSmall />
            </IntroTextButton>
          )}

          {showAllItems && (
            <IntroTextButton
              onClick={() => changeStep(SIZE_GUIDE_STEPS['MEASURE_JEANS'])}
            >
              {haveJeansHeader}
              <ArrowSmall />
            </IntroTextButton>
          )}

          <a href={sizeGuideUrl} target="_blank" download rel="noreferrer">
            <Paragraph>{`${sizeChartHeader} (pdf)`}</Paragraph>
            <ArrowSmall />
          </a>
        </IntroSelection>
      )}
    </SizeGuideStyled>
  );
};
