import { startTransition, useEffect, useState } from 'react';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import useSWR from 'swr';

import {
  ParsedProductDetailsAttribute,
  ParsedProductsApiResponse,
  ProductDetailsApiDocument,
  ProductDetailsApiImage,
  ProductDetailsValue,
} from '@hultafors/shared/types';

import { RELATED_PRODUCTS_PAGE_SIZE } from '@hultafors/snickers/api';
import { createUrl } from '@hultafors/snickers/helpers';
import {
  useGlobal,
  useProductBreadcrumbs,
  useSizeGuide,
} from '@hultafors/snickers/hooks';
import { findImagesByColor, Guide } from '@hultafors/snickers/product-helpers';
import {
  ProductDetailPageFragment,
  ProductSize,
  ProductSizeList,
  SizeCategory,
  SnickersProduct,
  SnickersProductDetails,
} from '@hultafors/snickers/types';

import { Breadcrumbs } from '../breadcrumbs/breadcrumbs';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { ImageAttribute } from '../ImageAttribute/ImageAttribute';
import { List } from '../List/List';
import { OptionsBar } from '../OptionsBar/OptionsBar';
import { Paragraph } from '../Paragraph/Paragraph';
import { ProductColumns } from '../ProductColumns/ProductColumns';
import { ProductHero } from '../ProductHero/ProductHero';
import { Products } from '../Products/Products';
import { ReadMoreLink } from '../ReadMoreLink/ReadMoreLink';
import { Section } from '../Section/Section';

const Accordion = dynamic(() =>
  import('../Accordion/Accordion').then((mod) => mod.Accordion),
);
const AccordionItem = dynamic(() =>
  import('../AccordionItem/AccordionItem').then((mod) => mod.AccordionItem),
);

const RelatedProducts = dynamic(() =>
  import('../RelatedProducts/RelatedProducts').then(
    (mod) => mod.RelatedProducts,
  ),
);

const ProductVideo = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.BynderVideo),
);

interface ProductPageContentProps {
  content: ProductDetailPageFragment;
  product: SnickersProductDetails;
  productCategory: SizeCategory;
  categorySlug: string;
  productHasSizeGuide?: boolean;
  productSizes?: ProductSizeList[];
  transformedSizeList?: ProductSize[];
  selectedImages?: ProductDetailsApiImage[];
  initialColor: ProductDetailsValue;
}

export const ProductPageContent: React.FC<ProductPageContentProps> = ({
  content,
  product,
  productCategory,
  productHasSizeGuide,
  productSizes,
  transformedSizeList,
  selectedImages: defaultSelectedImages = [],
  initialColor,
}) => {
  const { locale, asPath } = useRouter();
  const { global } = useGlobal();
  const breadcrumbs = useProductBreadcrumbs(asPath, product.name);
  const { selectedSize, savedRecommendation } = useSizeGuide();
  const [color, setColor] = useState<ProductDetailsValue>(initialColor);
  const [size, setSize] = useState<ProductDetailsValue>();
  const [selectedImages, setSelectedImages] = useState<
    ProductDetailsApiImage[]
  >(defaultSelectedImages);

  const SG = new Guide(product.sku || '');
  useEffect(() => {
    if (
      product.sku &&
      product.productSizes &&
      !size &&
      !selectedSize &&
      savedRecommendation
    ) {
      const recommendedSize = SG.getRecommendedSizeForProduct(
        savedRecommendation,
        product.productSizes,
      );
      if (recommendedSize?.value) {
        startTransition(() => {
          setSize({
            image: { url: '' },
            text: recommendedSize.value,
          });
        });
      }
    }
  }, [size, selectedSize, savedRecommendation]);

  const { data: relatedProducts, isValidating: relatedProductsLoading } =
    useSWR<ParsedProductsApiResponse<SnickersProduct>>(
      product.id &&
        product.related &&
        `/api/productrelationlist/${product.related}?${new URLSearchParams({
          pageSize: `${RELATED_PRODUCTS_PAGE_SIZE}`,
          productId: `${product.id}`,
          ...(locale && locale !== 'com' ? { locale } : {}),
        }).toString()}`,
      {
        refreshWhenHidden: false,
        revalidateOnFocus: false,
        revalidateOnMount: true,
      },
    );
  const { data: similarProducts, isValidating: similarProductsLoading } =
    useSWR<ParsedProductsApiResponse<SnickersProduct>>(
      product.id &&
        product.related &&
        `/api/productrelationlist/${product.similar}?${new URLSearchParams({
          pageSize: `${RELATED_PRODUCTS_PAGE_SIZE}`,
          productId: `${product.id}`,
          ...(locale && locale !== 'com' ? { locale } : {}),
        }).toString()}`,
      {
        revalidateOnMount: true,
      },
    );

  const documentsMapper = (item: ProductDetailsApiDocument, index: number) => {
    return (
      <ImageAttribute
        key={`Doc-${index}`}
        title={item.name}
        attributeName="documents"
        document={{
          filetype: item.filetype,
          name: item.name,
          size: item.size,
          url: item.url,
        }}
      />
    );
  };

  const sustainabilityMapper = (item: string, index: number) => (
    <ImageAttribute
      key={`Sustainability-${index}`}
      title=""
      attributes={item}
      attributeName="Sustainability"
    />
  );

  const moreAboutCertificates =
    content.moreOnCertifications.length > 0
      ? content.moreOnCertifications[0]
      : null;

  const moreAboutCare =
    content.moreAboutCare.length > 0 ? content.moreAboutCare[0] : null;

  const moreAboutMaterials = content.moreAboutMaterials.length
    ? content.moreAboutMaterials[0]
    : null;

  const moreAboutDocuments = content.moreOnDocuments.length
    ? content.moreOnDocuments[0]
    : null;

  const moreAboutSustainability = content.moreOnSustainability.length
    ? content.moreOnSustainability[0]
    : null;

  const certificationsMapper = (
    certification: ParsedProductDetailsAttribute,
    index: number,
  ) => {
    return (
      <ImageAttribute
        key={`Cert-${index}`}
        title={certification.name}
        attributes={certification.values}
        attributeName={content.certifications}
        image={certification.image}
      />
    );
  };

  const selectSize = (sizeGroup: ProductSizeList, item: ProductSize) => {
    startTransition(() => {
      setSize({ image: { url: '' }, text: item.value });
    });
  };

  useEffect(() => {
    if (selectedSize) {
      startTransition(() => {
        setSize({ image: { url: '' }, text: `${selectedSize}` });
      });
    }
  }, [selectedSize]);

  useEffect(() => {
    const images = findImagesByColor(product.images, color) || [];
    setSelectedImages(images);
  }, [color, asPath]);

  if (!product || !content || !global) {
    return null;
  }

  return (
    <>
      <Breadcrumbs {...breadcrumbs} slim />
      <ProductHero
        images={selectedImages}
        product={product}
        badgeText={global?.new || ''}
        rrpLabel={global.rrpLabel || ''}
        rrpIncludingVatLabel={global.rrpIncludingVatLabel || ''}
        setColor={setColor}
        color={color}
      />
      <OptionsBar
        sizeSelectorLabel={
          size?.text
            ? `${content.sizeLabel || ''} : ${size.text}`
            : content.chooseSize || ''
        }
        hasSizeGuide={productHasSizeGuide}
        sizeGuideLabel={content.sizeGuideLabel || ''}
        isOneSize={product.isOneSize}
        sizeChartPdfUrl={content.sizeChartPdf?.url}
        selectedSize={size}
        selectSize={selectSize}
        chooseSizeLabel={content.chooseSize || ''}
        sizeExtraShort={content.sizeExtraShort || ''}
        sizeShort={content.sizeShort || ''}
        sizeRegular={content.sizeRegular || ''}
        sizeLong={content.sizeLong || ''}
        sizeExtraLong={content.sizeExtraLong || ''}
        sizeNotStandardSize={content.sizeNotStandardSize || ''}
        productSizes={productSizes || []}
        productCategory={productCategory}
        sku={product.sku}
        transformedSizeList={transformedSizeList || []}
        similarProducts={similarProducts?.items || []}
      />
      <ProductColumns>
        <div>
          {product.technical_description && (
            <Paragraph className="ProductTechnicalDescription">
              {product.technical_description}
            </Paragraph>
          )}
          <List list={product.features} />
        </div>
        <div>
          <Accordion allowZeroExpanded>
            {!!product.certifications?.length && (
              <AccordionItem label={content.certifications || ''}>
                {product.certifications.map(certificationsMapper)}
                {moreAboutCertificates?.url && (
                  <ReadMoreLink href={createUrl(moreAboutCertificates.url)}>
                    {moreAboutCertificates.title}
                  </ReadMoreLink>
                )}
              </AccordionItem>
            )}
            {product.washing && (
              <AccordionItem label={content.care || ''}>
                <ImageAttribute
                  attributes={product.washing.values}
                  attributeName={content.care}
                />

                {moreAboutCare?.url && (
                  <ReadMoreLink href={createUrl(moreAboutCare.url)}>
                    {moreAboutCare.title}
                  </ReadMoreLink>
                )}
              </AccordionItem>
            )}
            {product.materials && (
              <AccordionItem label={content.materials}>
                <Paragraph className="MaterialsText">
                  {product.materials}
                </Paragraph>
                {!!product.cobranding?.values?.length && (
                  <ImageAttribute
                    attributes={product.cobranding.values}
                    attributeName="Cobranding"
                  />
                )}
                {moreAboutMaterials?.url && (
                  <ReadMoreLink href={createUrl(moreAboutMaterials.url)}>
                    {moreAboutMaterials.title}
                  </ReadMoreLink>
                )}
              </AccordionItem>
            )}
            {!!product.documents?.length && (
              <AccordionItem label={content.documents}>
                {product.documents.map(documentsMapper)}
                {moreAboutDocuments?.url && (
                  <ReadMoreLink href={createUrl(moreAboutDocuments.url)}>
                    {moreAboutDocuments.title}
                  </ReadMoreLink>
                )}
              </AccordionItem>
            )}
            {!!product.sustainability?.length && (
              <AccordionItem label={content.sustainability}>
                {product.sustainability.map(sustainabilityMapper)}
                {moreAboutSustainability?.url && (
                  <ReadMoreLink href={createUrl(moreAboutSustainability.url)}>
                    {moreAboutSustainability.title}
                  </ReadMoreLink>
                )}
              </AccordionItem>
            )}
          </Accordion>
        </div>
      </ProductColumns>
      {product?.productvideo && (
        <Section zeroTop largePaddingBottomDesktop>
          <Grid
            columnGap={[
              { columnGap: 15 },
              { breakpoint: 'desktop', columnGap: 30 },
            ]}
          >
            <GridChild>
              <ProductVideo videoId={product.productvideo} />
            </GridChild>
          </Grid>
        </Section>
      )}
      {product.related &&
        relatedProducts?.items &&
        relatedProducts.items.length > 0 && (
          <Section noTop borderTopDesktop largePaddingTop>
            <RelatedProducts header={content.relatedProductsHeader || ''}>
              <Grid
                columnGap={[
                  { columnGap: 15 },
                  { breakpoint: 'desktop', columnGap: 30 },
                ]}
                columns={[
                  { columns: 1 },
                  { breakpoint: 'mediumMobile', columns: 2 },
                  { breakpoint: 'desktop', columns: 4 },
                ]}
              >
                <Products
                  products={relatedProducts?.items}
                  badgeText={global?.new || ''}
                  isLoading={relatedProductsLoading}
                  numberOfItems={RELATED_PRODUCTS_PAGE_SIZE}
                  rrpLabel={global.rrpLabel || ''}
                  rrpIncludingVatLabel={global.rrpIncludingVatLabel || ''}
                />
              </Grid>
            </RelatedProducts>
          </Section>
        )}
      {product.similar &&
        similarProducts?.items &&
        similarProducts.items.length > 0 && (
          <Section noTop borderTopDesktop largePaddingTop>
            <RelatedProducts header={content.similarProductsHeader || ''}>
              <Grid
                columnGap={[
                  { columnGap: 15 },
                  { breakpoint: 'desktop', columnGap: 30 },
                ]}
                columns={[
                  { columns: 1 },
                  { breakpoint: 'mediumMobile', columns: 2 },
                  { breakpoint: 'desktop', columns: 4 },
                ]}
              >
                <Products
                  products={similarProducts.items}
                  badgeText={global?.new || ''}
                  isLoading={similarProductsLoading}
                  numberOfItems={RELATED_PRODUCTS_PAGE_SIZE}
                  rrpLabel={global.rrpLabel || ''}
                  rrpIncludingVatLabel={global.rrpIncludingVatLabel || ''}
                />
              </Grid>
            </RelatedProducts>
          </Section>
        )}
    </>
  );
};
