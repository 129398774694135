import { ChangeEvent, memo, startTransition, useEffect, useState } from 'react';

import { UnitsMinMax } from '@hultafors/shared/types';

import { useGlobal, useSizeGuide } from '@hultafors/snickers/hooks';
import {
  DEFAULT_RECOMMENDATION,
  DEFAULT_SELECTION,
  DEFAULT_SIZE_LISTS,
  DEFAULT_VALIDATION,
  getSizesForJeansGuide,
  Guide,
  SIZE_GUIDE_STEPS,
} from '@hultafors/snickers/product-helpers';
import {
  JeansSizeLists,
  MinMaxSizes,
  RegularSize,
  SizeConversion,
  SizeSelection,
  WaistList,
} from '@hultafors/snickers/types';

import { SlideIn } from '../SlideIn/SlideIn';

import { IntroScreen } from './IntroScreen/IntroScreen';
import { MeasureBody } from './MeasureBody/MeasureBody';
import { MeasureJeans } from './MeasureJeans/MeasureJeans';
import { MeasureTrousers } from './MeasureTrousers/MeasureTrousers';
import { OtherBrand } from './OtherBrand/OtherBrand';
import { Result } from './Result/Result';
import { SizeGuideStyled } from './SizeGuide.styled';

export const SIZE_GUIDE_DRAWER_ID = 'SizeGuideDrawer';

export type ChangeStepHandler = (currentStep: number, goBack?: boolean) => void;

interface Unit {
  key: keyof UnitsMinMax;
  name: string;
}

export const SizeGuide: React.FC = () => {
  const {
    sku = '',
    isOpen = false,
    similarProducts = [],
    setSelectedSize,
    sizes,
    productBadgeText = '',
    toggle,
  } = useSizeGuide();

  // const SIZE_GUIDE_DU_ID = 137; //old pt id.

  const guide = new Guide(sku);
  const { settings, sizeGuide } = useGlobal();
  const [step, setStep] = useState<{
    current: number;
    prev: number;
  }>({
    current: SIZE_GUIDE_STEPS['INTRO'] || 0,
    prev: SIZE_GUIDE_STEPS['INTRO'] || 0,
  });
  const [selection, setSelection] = useState(DEFAULT_SELECTION);
  const [recommendation, setRecommendation] = useState<SizeConversion>(
    DEFAULT_RECOMMENDATION,
  );
  const [minMax, setMinMax] = useState<MinMaxSizes>({
    legs: { cm: { max: 0, min: 0 }, inch: { max: 0, min: 0 } },
    waist: { cm: { max: 0, min: 0 }, inch: { max: 0, min: 0 } },
  });
  const [units, setUnits] = useState<Unit[]>([]);
  const [otherBrands, setOtherBrands] = useState<any[]>([]);

  const [validation, setValidation] = useState<any>(DEFAULT_VALIDATION);
  const [sizeLists, setSizeLists] = useState<any>(
    SIZE_GUIDE_STEPS['MEASURE_JEANS']
      ? {
          [SIZE_GUIDE_STEPS['MEASURE_JEANS']]: DEFAULT_SIZE_LISTS,
        }
      : undefined,
  );

  useEffect(() => {
    if (sizeGuide?.inch) {
      startTransition(() => {
        setUnits([
          { key: 'inch', name: sizeGuide.inch || '' },
          { key: 'cm', name: 'cm' },
        ]);
      });
    }
  }, [sizeGuide]);

  useEffect(() => {
    if (isOpen) {
      resetGuide();
    }
  }, [isOpen]);

  const resetGuide = () => {
    if ('us' === settings.market) {
      DEFAULT_SELECTION.unit = 'inch';
    }
    startTransition(() => {
      if (SIZE_GUIDE_STEPS['INTRO']) {
        setStep({
          current: SIZE_GUIDE_STEPS['INTRO'],
          prev: SIZE_GUIDE_STEPS['INTRO'],
        });
      }
      setSelection(DEFAULT_SELECTION);
      setRecommendation(DEFAULT_RECOMMENDATION);
      setValidation(DEFAULT_VALIDATION);
      if (SIZE_GUIDE_STEPS['MEASURE_JEANS']) {
        setSizeLists({
          [SIZE_GUIDE_STEPS['MEASURE_JEANS']]: DEFAULT_SIZE_LISTS,
        });
      }
    });
  };

  const changeStep: ChangeStepHandler = (
    currentStep: number,
    goBack?: boolean,
  ) => {
    let newRecommendation = { ...recommendation };
    let newSelection = { ...selection };

    let newValidation = { ...validation };

    if (currentStep === SIZE_GUIDE_STEPS['RESULT']) {
      newValidation = guide.validateScreen(
        SIZE_GUIDE_STEPS,
        step.current,
        minMax,
        newSelection,
        validation,
      );
    }

    if (newValidation.error) {
      startTransition(() => {
        setValidation(newValidation);
      });
    } else {
      // TODO load and set state here
      loadSizeLists(currentStep);

      if ('us' === settings.market) {
        DEFAULT_SELECTION.unit = 'inch';
      }

      if (
        step.current !== SIZE_GUIDE_STEPS['RESULT'] &&
        currentStep !== SIZE_GUIDE_STEPS['RESULT']
      ) {
        // Clear recommendation and selection when switching STEPS, but not from or to result screen
        newRecommendation = DEFAULT_RECOMMENDATION;
        newSelection = DEFAULT_SELECTION;
      }

      const newMinMax = guide.getMinMaxWaistLegs(currentStep);
      if (newMinMax) {
        startTransition(() => {
          setMinMax(newMinMax);
        });
      }
      if (newSelection) {
        startTransition(() => {
          setSelection(newSelection);
        });
      }
      if (newRecommendation) {
        startTransition(() => {
          setRecommendation(newRecommendation);
        });
      }
      if (newValidation) {
        startTransition(() => {
          setValidation(newValidation);
        });
      }
      startTransition(() => {
        setStep({
          current: currentStep,
          prev: goBack ? 0 : step.current,
        });
      });
    }
  };

  const loadSizeLists = (currentStep: number) => {
    let newSizeLists: JeansSizeLists | null = null;

    // Singleton checks if size lists are loaded
    switch (currentStep) {
      case SIZE_GUIDE_STEPS['MEASURE_JEANS']:
        newSizeLists = getSizesForJeansGuide(sku);
        break;
      default:
        break;
    }
    if (newSizeLists) {
      startTransition(() => {
        setSizeLists({
          ...sizeLists,
          [currentStep]: newSizeLists,
        });
      });
    }
  };

  const doChangeSizeMeasure = (
    value: string,
    name: string,
    type: keyof WaistList,
    unit: keyof UnitsMinMax,
  ) => {
    const sizes = getWaistLegSizeHelper(value, name);

    const unitType = unit ? unit : selection.unit;
    const recommend = guide.getRecommendationsFromMeasure(
      sizes.waistSize,
      sizes.legSize,
      unitType,
      minMax,
      type,
    );
    startTransition(() => {
      setRecommendation(recommend ? recommend : recommendation);
      setSelection({
        ...selection,
        legSize: parseInt(`${sizes.legSize}`),
        unit: unitType,
        waistSize: parseInt(`${sizes.waistSize}`),
      });
    });
  };

  const changeSizeMeasure = (
    e: ChangeEvent<HTMLInputElement>,
    type: keyof WaistList,
    unit: keyof UnitsMinMax,
  ) => {
    doChangeSizeMeasure(e.target.value, e.target.name, type, unit);
  };

  const triggerChangeSizeMeasure = (
    type: keyof WaistList,
    unit: keyof UnitsMinMax,
  ) => {
    doChangeSizeMeasure(`${selection.waistSize}`, 'waistSize', type, unit);
  };

  const changeSizeOtherBrands = (
    recommendedSize: number,
    otherBrand: SizeSelection['otherBrand'],
  ) => {
    const recommend = guide.getRecommendationsFromOtherBrand(recommendedSize);
    startTransition(() => {
      setRecommendation(recommend ? recommend : recommendation);
      setSelection({
        ...selection,
        otherBrand,
      });
    });
  };

  const changeSizeJeans = (size: string, measureType: string) => {
    const sizes = getWaistLegSizeHelper(size, measureType);

    const recommend = guide.getRecommendationsFromJeans(
      sizes.waistSize,
      sizes.legSize,
      SIZE_GUIDE_STEPS['MEASURE_JEANS']
        ? sizeLists[SIZE_GUIDE_STEPS['MEASURE_JEANS']]
        : [],
    );
    startTransition(() => {
      setRecommendation(recommend ? recommend : recommendation);
      setSelection({
        ...selection,
        legSize: parseInt(`${sizes.legSize}`, 10),
        waistSize: parseInt(`${sizes.waistSize}`, 10),
      });
    });
  };

  const getWaistLegSizeHelper = (size: string, measureType: string) => {
    let waistSize;
    let legSize;
    if (measureType === 'waistSize') {
      waistSize = size;
      legSize = selection.legSize;
    } else {
      legSize = size;
      waistSize = selection.waistSize;
    }
    return {
      legSize,
      waistSize,
    };
  };

  const setSizeOnDetailPage = (newRecommendation: SizeConversion) => {
    if (
      sku &&
      typeof newRecommendation?.current !== 'number' &&
      typeof newRecommendation?.current !== 'undefined' &&
      newRecommendation.current?.size
    ) {
      // Select size on detail page if we are not in standalone mode
      startTransition(() => {
        setSelectedSize((newRecommendation.current as RegularSize).size);
      });
    }
  };

  const changeUnit = (
    event: React.ChangeEvent<HTMLSelectElement>,
    type: keyof WaistList,
  ) => {
    const unit = units.find(({ key }) => key === event.target.value);
    if (unit?.key) {
      startTransition(() => {
        setSelection({
          ...selection,
          unit: unit.key,
        });
      });

      // triggerchangesizemeasure is neccessary to calculate new recommendations when unit changes
      triggerChangeSizeMeasure(type, unit.key);
    }
  };

  if (!sizeGuide) {
    return null;
  }

  return (
    <SizeGuideStyled>
      <SlideIn
        toggle={toggle}
        isOpen={isOpen}
        headerText=""
        headerIcon="/assets/gfx/measure.svg"
        fromLeft={false}
        isGuide
        id={SIZE_GUIDE_DRAWER_ID}
        changeStepSG={changeStep}
        currentStepNumber={step.current}
        backText={sizeGuide?.back || 'Back'}
      >
        {step.current === SIZE_GUIDE_STEPS['INTRO'] && (
          <IntroScreen
            changeStep={changeStep}
            title={sizeGuide.sizeGuideTitle || ''}
            backText={sizeGuide.back || ''}
            knowWaistLegHeader={sizeGuide.waistLegHeader || ''}
            haveTrousersHeader={sizeGuide.haveTrousersHeader || ''}
            otherBrandHeader={sizeGuide.otherBrandHeader || ''}
            howToMeasureHeader={sizeGuide.howToMeasureHeader || ''}
            haveJeansHeader={sizeGuide.measurementsJeansTitle || ''}
            sizeChartHeader={sizeGuide.sizeChartTitle || ''}
            sizeChart={sizeGuide.sizeChart}
          />
        )}
        {step.current === SIZE_GUIDE_STEPS['OTHER_BRAND'] && (
          <OtherBrand
            toggleGuide={toggle}
            changeStep={changeStep}
            selection={selection}
            changeSize={changeSizeOtherBrands}
            steps={SIZE_GUIDE_STEPS}
            title={sizeGuide.measurementsBrandTitle || ''}
            backText={sizeGuide.back || ''}
            showMySize={sizeGuide.showMySize || ''}
            chooseBrandText={sizeGuide.chooseBrand || ''}
            chooseLegLengthText={sizeGuide.chooseLegLengthText || ''}
            chooseSizeText={sizeGuide.headerText || ''}
          />
        )}

        {step.current === SIZE_GUIDE_STEPS['MEASURE_BODY'] && (
          <MeasureBody
            toggleGuide={toggle}
            changeStep={changeStep}
            selection={selection}
            steps={SIZE_GUIDE_STEPS}
            sku={sku}
            title={sizeGuide.measurementsGuideTitle || ''}
            yourMeasurementsText={sizeGuide.typeMeasurementsLabel || ''}
            backText={sizeGuide.back || ''}
            waistText={sizeGuide.lowerWaist || ''}
            showMySize={sizeGuide.showMySize || ''}
            changeSize={changeSizeMeasure}
            units={units}
            changeUnit={changeUnit}
            validation={validation}
            validationMsg={sizeGuide.waistLegsOutOfBoundsError || ''}
            minMax={minMax}
          />
        )}
        {step.current === SIZE_GUIDE_STEPS['MEASURE_TROUSERS'] && (
          <MeasureTrousers
            toggleGuide={toggle}
            changeStep={changeStep}
            selection={selection}
            steps={SIZE_GUIDE_STEPS}
            sku={sku}
            title={sizeGuide.measurementsOwnPairTitle || ''}
            yourMeasurementsText={sizeGuide.typeMeasurementsLabel || ''}
            backText={sizeGuide.back || ''}
            waistText={sizeGuide.waist || ''}
            showMySize={sizeGuide.showMySize || ''}
            changeSize={changeSizeMeasure}
            units={units}
            changeUnit={changeUnit}
            validation={validation}
            validationMsg={sizeGuide.waistLegsOutOfBoundsError || ''}
            minMax={minMax}
          />
        )}
        {step.current === SIZE_GUIDE_STEPS['MEASURE_JEANS'] && (
          <MeasureJeans
            toggleGuide={toggle}
            changeStep={changeStep}
            selection={selection}
            sku={sku}
            title={sizeGuide.measurementsJeansTitle || ''}
            backText={sizeGuide.back || ''}
            waistText={sizeGuide.waist || ''}
            insideLegText={sizeGuide.insideLeg || ''}
            showMySize={sizeGuide.showMySize || ''}
            changeSize={changeSizeJeans}
            sizeList={sizeLists[SIZE_GUIDE_STEPS['MEASURE_JEANS']].list}
          />
        )}
        {step.current === SIZE_GUIDE_STEPS['RESULT'] && (
          <Result
            backText={sizeGuide.back || ''}
            changeStep={changeStep}
            currentStep={step}
            menLabel={sizeGuide.menLabel || ''}
            notAvailableText={sizeGuide.sizeNotAvailableText || ''}
            notMatchingSize={sizeGuide.couldNotFindRecommendation || ''}
            notStandardSizeText={sizeGuide.isNotStandardSize || ''}
            productBadgeText={productBadgeText}
            productSizes={sizes}
            recommendation={recommendation}
            rememberSizes={sizeGuide.rememberSizes || ''}
            // setSizeOnDetailPage={setSizeOnDetailPage}
            similarProductHeader={sizeGuide.similarProductHeader || ''}
            similarProducts={similarProducts}
            sixSeriesLabel={sizeGuide.sixSeriesLabel || ''}
            sku={sku}
            threeSeriesLabel={sizeGuide.threeSeriesLabel || ''}
            title={sizeGuide.yourSize || ''}
            toggleGuide={toggle}
            womenLabel={sizeGuide.womanLabel || ''}
          />
        )}
      </SlideIn>
    </SizeGuideStyled>
  );
};

export default memo(SizeGuide);
