import { startTransition, useEffect, useState } from 'react';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { useGlobal } from '@hultafors/snickers/hooks';

import { DesktopMenu, DesktopMenuType } from '../DesktopMenu/DesktopMenu';
import { SlideInToggler } from '../SlideIn/SlideIn';

import { HeaderStyled } from './Header.styled';

const Favorites = dynamic(() =>
  import('../Favorites/Favorites').then((module) => module.Favorites),
);
const Menu = dynamic(() =>
  import('../Menu/Menu').then((module) => module.Menu),
);
const Navigation = dynamic(() =>
  import('../Navigation/Navigation').then((module) => module.Navigation),
);

export const Header: React.FC = () => {
  const { global, allMenuItems, menuExtra } = useGlobal();

  const defaultOpenSettings = {
    cart: false,
    desktopMenu: false,
    favorites: false,
    mobileMenu: false,
  };

  const [openDesktopMenuType, setOpenDesktopMenuType] =
    useState<DesktopMenuType | null>(null);
  const [isOpen, setIsOpen] = useState(defaultOpenSettings);

  const { route, pathname } = useRouter();

  useEffect(() => {
    startTransition(() => {
      setOpenDesktopMenuType(null);
      setIsOpen(defaultOpenSettings);
    });
  }, [route]);

  const toggleMenu: SlideInToggler = () => {
    const open = !isOpen.mobileMenu;
    startTransition(() => {
      setIsOpen({ ...defaultOpenSettings, mobileMenu: open });
    });
  };

  const toggleDesktopMenu: SlideInToggler<DesktopMenuType> = (type) => {
    startTransition(() => {
      setOpenDesktopMenuType(
        !type || openDesktopMenuType === type ? null : type,
      );
    });
  };

  useEffect(() => {
    startTransition(() => {
      setIsOpen({
        ...isOpen,
        desktopMenu: !!openDesktopMenuType,
      });
    });
  }, [openDesktopMenuType]);

  const toggleFavoritesMenu = () => {
    const open = !isOpen.favorites;
    startTransition(() => {
      setIsOpen({ ...defaultOpenSettings, favorites: open });
    });
  };

  // Ugly hack to set transparent menu when moving Nav to root
  const isMenuTransparent = () => {
    if (pathname.startsWith('/interviews')) {
      return true;
    }
    return false;
  };

  return (
    <HeaderStyled>
      <Navigation
        isTransparent={isMenuTransparent()}
        isOpen={isOpen.desktopMenu}
        openDesktopMenuType={openDesktopMenuType}
        toggleMenu={toggleMenu}
        toggleDesktopMenu={toggleDesktopMenu}
        toggleFavoritesMenu={toggleFavoritesMenu}
        menuData={allMenuItems}
      />

      <Menu
        isOpen={isOpen.mobileMenu}
        toggleMenu={toggleMenu}
        headerText={global?.menuLabel || ''}
        menuData={allMenuItems}
        menuExtras={menuExtra}
      />

      <DesktopMenu
        isOpen={isOpen.desktopMenu}
        type={openDesktopMenuType}
        menuItems={allMenuItems}
        menuExtras={menuExtra}
        toggleDesktopMenu={toggleDesktopMenu}
      />

      <Favorites
        isOpen={isOpen.favorites}
        toggleMenu={toggleFavoritesMenu}
        header={global?.favoritesHeader || ''}
        clearText={global?.clear || ''}
        newText={global?.new || ''}
      />
    </HeaderStyled>
  );
};
