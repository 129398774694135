import { createContext, startTransition, useState } from 'react';

interface WorkwearGuideContextValue {
  isOpen: boolean;
  toggle(): void;
}

export const WorkwearGuideContext =
  createContext<WorkwearGuideContextValue | null>(null);

export interface WorkwearProviderProps {
  children?: React.ReactNode;
  value?: WorkwearGuideContextValue;
}

export const WorkwearProvider: React.FC<WorkwearProviderProps> = ({
  children,
  value,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(!!value?.isOpen);
  const toggle = async () => {
    startTransition(() => {
      setIsOpen(!isOpen);
    });
  };

  return (
    <WorkwearGuideContext.Provider value={{ isOpen, toggle }}>
      {children}
    </WorkwearGuideContext.Provider>
  );
};
