import { startTransition, useEffect } from 'react';

import { ProductDetailsValue } from '@hultafors/shared/types';

import { useGlobal, useSizeGuide } from '@hultafors/snickers/hooks';
import {
  ProductSize,
  ProductSizeList,
  SizeCategory,
  SnickersProduct,
} from '@hultafors/snickers/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { OptionsBarButton } from '../OptionsBarButton/OptionsBarButton';
import { SizeSelector } from '../SizeSelector/SizeSelector';

import { Option, OptionsBarInner, OptionsBarStyled } from './OptionsBar.styled';

// TODO obfuscate this
const DEFAULT_SIZE_GUIDE =
  'https://hf-hcms-stagingdoc1.azureedge.net/027/en_19841_637158206420546349_Ol2s1VyTnAHDnb7am9zhaNow6L41';

export interface OptionsBarProps {
  sizeSelectorLabel?: string;
  sizeGuideLabel?: string;
  sizeChartPdfUrl?: string;
  chooseSizeLabel?: string;
  hasSizeGuide?: boolean;
  isOneSize?: boolean;
  selectSize(sizeGroup: ProductSizeList, item: ProductSize): void;
  selectedSize?: ProductDetailsValue;
  productSizes: ProductSizeList[];
  productCategory: SizeCategory;
  sizeExtraShort?: string;
  sizeShort?: string;
  sizeRegular?: string;
  sizeLong?: string;
  sizeExtraLong?: string;
  sizeNotStandardSize?: string;
  sku?: string;
  transformedSizeList: ProductSize[];
  similarProducts: SnickersProduct[];
}

export const OptionsBar: React.FC<OptionsBarProps> = ({
  sizeSelectorLabel = '',
  sizeGuideLabel = '',
  hasSizeGuide,
  isOneSize,
  sizeChartPdfUrl,
  selectSize,
  selectedSize,
  productSizes,
  productCategory,
  chooseSizeLabel,
  sizeExtraShort,
  sizeShort,
  sizeRegular,
  sizeLong,
  sizeExtraLong,
  sizeNotStandardSize,
  sku = '',
  transformedSizeList,
  similarProducts = [],
}) => {
  const { global } = useGlobal();
  const {
    toggle: toggleSizeGuide,
    setStandAlone,
    setSizes,
    setSku,
    setSimilarProducts,
    setProductBadgeText,
  } = useSizeGuide();

  // Setup size-guide
  useEffect(() => {
    startTransition(() => {
      setStandAlone(false);
      setSizes(transformedSizeList);
      setSku(sku);
      setSimilarProducts(similarProducts);
      setProductBadgeText(global?.new || '');
    });
    return () => {
      startTransition(() => {
        setStandAlone(true);
        setSizes([]);
        setSku('');
        setSimilarProducts([]);
        setProductBadgeText('');
      });
    };
  }, []);

  return (
    <OptionsBarStyled>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 12 },
          ]}
        >
          <OptionsBarInner>
            <Option>
              <SizeSelector
                buttonLabel={sizeSelectorLabel}
                sizes={productSizes}
                category={productCategory}
                toggleSelectSize={selectSize}
                selectedSize={selectedSize}
                headerText={chooseSizeLabel}
                doneText={chooseSizeLabel}
                extraShortText={sizeExtraShort}
                shortText={sizeShort}
                regularText={sizeRegular}
                longText={sizeLong}
                extraLongText={sizeExtraLong}
                notStandardSizeText={sizeNotStandardSize}
              />
            </Option>

            {hasSizeGuide ? (
              <Option>
                <OptionsBarButton sizeGuide onClick={toggleSizeGuide}>
                  {sizeGuideLabel}
                </OptionsBarButton>
              </Option>
            ) : (
              <Option $oneSize={isOneSize}>
                <OptionsBarButton sizeGuide>
                  {sizeChartPdfUrl ? (
                    <a
                      href={sizeChartPdfUrl}
                      download
                      target="_blank"
                      rel="noreferrer"
                    >
                      {sizeGuideLabel}
                    </a>
                  ) : (
                    <a
                      href={DEFAULT_SIZE_GUIDE}
                      download
                      target="_blank"
                      rel="noreferrer"
                    >
                      {sizeGuideLabel}
                    </a>
                  )}
                </OptionsBarButton>
              </Option>
            )}
          </OptionsBarInner>
        </GridChild>
      </Grid>
    </OptionsBarStyled>
  );
};
