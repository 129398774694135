import { startTransition, useEffect, useState } from 'react';

import { convertDetailsToList } from '@hultafors/snickers/api';
import { useGlobal, useSizeGuide } from '@hultafors/snickers/hooks';
import { Guide } from '@hultafors/snickers/product-helpers';
import {
  SizeConversion,
  SnickersProduct,
  SnickersProductDetails,
} from '@hultafors/snickers/types';

import { Button } from '../../Button/Button';
import { GuideHero } from '../../GuideHero/GuideHero';
import { H4 } from '../../H4/H4';
import { Loader } from '../../Loader/Loader';
import { Paragraph } from '../../Paragraph/Paragraph';
import { Product } from '../../Product/Product';
import { SizeGuideStyled } from '../SizeGuide.styled';

export interface ResultProps {
  toggleGuide(...args: any[]): unknown;
  changeStep(...args: any[]): unknown;
  currentStep: any;
  recommendation: SizeConversion;
  sku?: string;
  productSizes?: any[];
  title?: string;
  backText?: string;
  introText?: string;
  sixSeriesLabel?: string;
  threeSeriesLabel?: string;
  notAvailableText?: string;
  similarProductHeader?: string;
  similarProducts?: any[];
  rememberSizes?: string;
  productBadgeText?: string;
  setSizeOnDetailPage?(...args: any[]): unknown;
  notMatchingSize?: string;
  womenLabel?: string;
  menLabel?: string;
  notStandardSizeText?: string;
}

export const Result: React.FC<ResultProps> = ({
  sku,
  recommendation,
  productSizes = [],
  setSizeOnDetailPage,
  rememberSizes,
  notStandardSizeText,
  productBadgeText,
  similarProductHeader,
  notAvailableText,
  sixSeriesLabel,
  threeSeriesLabel,
  notMatchingSize,
  womenLabel,
  menLabel,
  changeStep,
  currentStep,
  backText,
  similarProducts = [],
  introText,
  title,
  toggleGuide,
}) => {
  const { setSavedRecommendation } = useSizeGuide();
  const [product, setProduct] = useState<SnickersProduct | undefined>(
    undefined,
  );
  // const [sizesStore, setSizesStore] = useState<any>(undefined);
  const [noSizeFound, setNoSizeFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [productHasSize, setProductHasSize] = useState(true);
  const [currentTab, setCurrentTab] = useState<number>(1);
  const [isSizeMen, setIsSizeMen] = useState(true);
  const [isSizeWomen, setIsSizeWomen] = useState(true);
  const [isSpecialSize, setIsSpecialSize] = useState(false);

  const SG = new Guide(sku || '');

  const { global, settings } = useGlobal();

  const setRecommendation = () => {
    if (!isRecommendation()) {
      startTransition(() => {
        setNoSizeFound(true);
      });
    } else if (sku) {
      checkSizesForProduct();
    }
  };

  useEffect(() => {
    // TODO load recommendation on mount
    startTransition(() => {
      setRecommendation();
    });
  }, []);

  const isRecommendation = () => {
    const hits = Object.entries(recommendation).filter((x: any) => x?.[1] > 0);
    // Check if we have women size
    startTransition(() => {
      setIsSizeWomen(hits.some((x) => x[0] === 'women'));
    });

    switch (hits.length) {
      case 0:
        return false;
      case 1:
        if (hits[0]?.[0] === 'women') {
          // We only have women size, switch tab
          startTransition(() => {
            setIsSizeMen(false);
            setCurrentTab(2);
          });
        }
        return true;
      default:
        return true;
    }
  };

  const checkSizesForProduct = () => {
    const size = SG.getRecommendedSizeForProduct(
      recommendation,
      productSizes || [],
    );
    startTransition(() => {
      setProductHasSize(size ? true : false);
    });

    if (!size) {
      // Current product does not have recommended size
      loadProducts();
    } else {
      if (setSizeOnDetailPage) {
        startTransition(() => {
          setSizeOnDetailPage(recommendation);
        });
      }
      startTransition(() => {
        setIsSpecialSize(!!size.toOrder);
      });
    }
  };

  const loadProducts = async () => {
    try {
      startTransition(() => {
        setIsLoading(true);
      });
      const { productList = '' } = settings.pt || {};
      const params = new URLSearchParams({ skipModels: 'true' });
      similarProducts.forEach(({ productId }) => {
        params.append('productIds', productId);
      });
      const url = new URL(
        `/api/productdetailslist/${productList}?${params.toString()}`,
      );
      const response = await fetch(url);
      if (!response.ok) {
        const { url: responseUrl, status, statusText } = response;
        const headers: Record<string, string> = {};
        response.headers.forEach((value, key) => {
          headers[key] = value;
        });
        console.error({
          headers,
          status,
          statusText,
          url: responseUrl,
        });
        throw new Error(`${status} ${statusText}`);
      }
      const data: SnickersProductDetails[] = await response.json();
      const found = data.find((item) => {
        return !!SG.getRecommendedSizeForProduct(
          recommendation,
          item.productSizes,
        );
      });
      if (found) {
        const size = SG.getRecommendedSizeForProduct(
          recommendation,
          found.productSizes,
        );
        if (size) {
          startTransition(() => {
            setProduct(
              convertDetailsToList(found, settings.pt?.productList || '23'),
            );
            setIsSpecialSize(!!size.toOrder);
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
    startTransition(() => {
      setIsLoading(false);
    });
  };

  const doRememberSizes = () => {
    const newRecommendation = { ...recommendation };
    delete newRecommendation.current;
    startTransition(() => {
      setSavedRecommendation(newRecommendation);
    });
    toggleGuide();
  };

  const isSixSeries = () => {
    if (
      recommendation?.current &&
      typeof recommendation.current !== 'number' &&
      recommendation.current?.name
    ) {
      switch (recommendation.current.name) {
        case 'size':
          return true;
        default:
          return false;
      }
    }
    return false;
  };

  return (
    <SizeGuideStyled>
      <GuideHero
        title={title}
        introText={introText}
        isBig={false}
        backText={backText}
        currentStep={currentStep?.current}
        backToStep={currentStep?.prev}
        changeStep={changeStep}
        guideType="size"
        isResult
      />

      <div className="ContentWrapper Result">
        {noSizeFound ? (
          <Paragraph center className="NoSizeFound">
            {notMatchingSize}
          </Paragraph>
        ) : (
          <>
            <div>
              {!sku ? (
                <div className="tabs">
                  <div className="tab">
                    <input
                      type="radio"
                      id="1"
                      name="tab-group-1"
                      checked={currentTab === 1}
                      onChange={(e) => {
                        startTransition(() => {
                          setCurrentTab(parseInt(e.target.id, 10));
                        });
                      }}
                    />
                    <label htmlFor="1">{menLabel}</label>

                    <div className="content">
                      {isSizeMen ? (
                        <>
                          <div className="BsgBox SixSeries">
                            <span className="SizeLabel">{sixSeriesLabel}</span>
                            <span className="Size">
                              {`${recommendation.size}${
                                isSpecialSize ? '*' : ''
                              }`}
                            </span>
                          </div>
                          <div className="BsgBox Gray">
                            <span className="SizeLabel">
                              {threeSeriesLabel}
                            </span>
                            <span className="Size">
                              {recommendation.size3}
                              {isSpecialSize ? '*' : ''}
                            </span>
                          </div>
                        </>
                      ) : (
                        <div className="ErrorMessage">{notMatchingSize}</div>
                      )}
                    </div>
                  </div>

                  <div className="tab">
                    <input
                      type="radio"
                      id="2"
                      name="tab-group-1"
                      checked={currentTab === 2}
                      onChange={(e) => {
                        startTransition(() => {
                          setCurrentTab(parseInt(e.target.id, 10));
                        });
                      }}
                    />
                    <label htmlFor="2">{womenLabel}</label>

                    <div className="content">
                      {isSizeWomen ? (
                        <div className="BsgBox SixSeries">
                          <span className="SizeLabel">{sixSeriesLabel}</span>
                          <span className="Size">
                            {`${recommendation.women}${
                              isSpecialSize ? '*' : ''
                            }`}
                          </span>
                        </div>
                      ) : (
                        <div className="ErrorMessage">{notMatchingSize}</div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={`BsgBox ${isSixSeries() ? 'SixSeries' : 'Gray'}`}
                >
                  {typeof recommendation.current !== 'number' &&
                    recommendation.current?.name !== 'women' && (
                      <span className="SizeLabel">
                        {isSixSeries() ? sixSeriesLabel : threeSeriesLabel}
                      </span>
                    )}
                  <span className="Size">
                    {!recommendation.current
                      ? recommendation.size
                      : (typeof recommendation.current !== 'number' &&
                          recommendation.current?.size) ||
                        ''}
                    {isSpecialSize ? '*' : ''}
                  </span>
                </div>
              )}
              {sku && !productHasSize && (
                <div className="ErrorMessage">{notAvailableText}</div>
              )}
            </div>

            {isLoading ? (
              <div className="LoaderWrapper">
                <Loader small />
              </div>
            ) : (
              product && (
                <>
                  <H4 center>{similarProductHeader}</H4>
                  <Product
                    product={product as SnickersProduct}
                    badgeText={productBadgeText || ''}
                    rrpLabel={global.rrpLabel || ''}
                    rrpIncludingVatLabel={global.rrpIncludingVatLabel || ''}
                  />
                </>
              )
            )}
          </>
        )}
        {isSpecialSize && (
          <Paragraph className="NotStandardSize">
            {notStandardSizeText}
          </Paragraph>
        )}
      </div>

      <div className="Summary">
        <Button onClick={doRememberSizes} disabled={noSizeFound}>
          {rememberSizes}
        </Button>
      </div>
    </SizeGuideStyled>
  );
};
