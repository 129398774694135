import styled, { css } from 'styled-components';

import { colors, fontFamilies, fontWeights } from '@hultafors/snickers/helpers';

interface CategoryCardProps {
  $selected?: boolean;
}

export const Title = styled.p`
  font-family: ${fontFamilies.mikro};
  font-weight: ${fontWeights.bold};
  margin-block-start: 0;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.white};
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
  word-break: break-word;
  hyphens: auto;
`;

export const CategoryCard = styled.button<CategoryCardProps>`
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  position: relative;
  height: 245px;
  box-sizing: border-box;
  margin-block-end: 15px;
  outline: 2px solid transparent;
  outline-offset: -2px;
  cursor: pointer;

  ${({ $selected }) =>
    $selected &&
    css`
      outline-color: ${colors.highlightBlue};
    `}
`;

export const ImageWrapper = styled.div`
  position: absolute;
  inset: 0;
  background-color: ${colors.gray3};
  z-index: -1;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 1rem 0;

  > * + * {
    margin-block-start: 0.5rem;
  }
`;
