import {
  createContext,
  Dispatch,
  SetStateAction,
  startTransition,
  useState,
} from 'react';

import { useLocalStorageValue } from '@react-hookz/web';

import {
  ProductSize,
  SizeConversion,
  SnickersProduct,
} from '@hultafors/snickers/types';

interface SizeGuideContextValue {
  isOpen: boolean;
  standAlone: boolean;
  setStandAlone: Dispatch<SetStateAction<boolean>>;
  toggle(): void;
  selectedSize: number | undefined;
  setSelectedSize: Dispatch<SetStateAction<number | undefined>>;
  sizes: ProductSize[];
  setSizes: Dispatch<SetStateAction<ProductSize[]>>;
  sku: string;
  setSku: Dispatch<SetStateAction<string>>;
  productBadgeText: string;
  setProductBadgeText: Dispatch<SetStateAction<string>>;
  similarProducts: SnickersProduct[];
  setSimilarProducts: Dispatch<SetStateAction<SnickersProduct[]>>;
  savedRecommendation: SizeConversion | null;
  setSavedRecommendation(value: SizeConversion | null): void;
}

export const SizeGuideContext = createContext<SizeGuideContextValue | null>(
  null,
);

export interface SizeGuideProviderProps {
  children?: React.ReactNode;
  value?: SizeGuideContextValue;
}

export const SizeGuideProvider: React.FC<SizeGuideProviderProps> = ({
  children,
  value,
}) => {
  const recommendation = useLocalStorageValue<SizeConversion | null>(
    'snickersworkwear_saved_recommendation',
    {
      defaultValue: null,
      initializeWithValue: false,
    },
  );
  const [isOpen, setIsOpen] = useState<boolean>(!!value?.isOpen);
  const [standAlone, setStandAlone] = useState<boolean>(!!value?.standAlone);
  const [selectedSize, setSelectedSize] = useState<number>();
  const [sizes, setSizes] = useState<ProductSize[]>(value?.sizes || []);
  const [sku, setSku] = useState<string>(value?.sku || '');
  const [productBadgeText, setProductBadgeText] = useState<string>(
    value?.productBadgeText || '',
  );
  const [similarProducts, setSimilarProducts] = useState<SnickersProduct[]>(
    value?.similarProducts || [],
  );
  const toggle = () => {
    startTransition(() => {
      setIsOpen(!isOpen);
    });
  };
  return (
    <SizeGuideContext.Provider
      value={{
        isOpen,
        productBadgeText,
        savedRecommendation: recommendation.value || null,
        selectedSize,
        setProductBadgeText,
        setSavedRecommendation: recommendation.set,
        setSelectedSize,
        setSimilarProducts,
        setSizes,
        setSku,
        setStandAlone,
        similarProducts,
        sizes,
        sku,
        standAlone,
        toggle,
      }}
    >
      {children}
    </SizeGuideContext.Provider>
  );
};
