import Image from 'next/image';

import { ImageBasicFragment } from '@hultafors/snickers/types';

import CloseSvg from './close.svg';
import {
  BackDrop,
  DialogContent,
  ImageWrapper,
  StyledDialog,
  StyledIFrameContainer,
} from './SubscriptionDialog.styled';
export interface SubscriptionDialogProps {
  formUrl?: string;
  image?: ImageBasicFragment;
  onHandleClose?(...args: any[]): void;
}

export const SubscriptionDialog: React.FC<SubscriptionDialogProps> = ({
  formUrl,
  image,
  onHandleClose,
}) => {
  return (
    <>
      <BackDrop />
      <StyledDialog
        isFullscreen={false}
        onClose={onHandleClose}
        closeButtonLabel={<CloseSvg width={16} />}
      >
        <DialogContent>
          {image?.url && (
            <ImageWrapper>
              <Image src={image.url} alt={image.alt || ''} fill sizes="400px" />
            </ImageWrapper>
          )}
          <StyledIFrameContainer
            title="dialog-form"
            src={formUrl || ''}
          ></StyledIFrameContainer>
        </DialogContent>
      </StyledDialog>
    </>
  );
};
