import Image from 'next/image';

import { datoImageLoader } from '@hultafors/shared/helpers/dato-image-loader/dato-image-loader';

import { ImageFragment } from '@hultafors/snickers/types';

import {
  BackgroundColors,
  Content,
  GenericPlugStyled,
  Inner,
  Intro,
  Label,
  Name,
  Title,
} from './GenericPlug.styled';
import LinkSvg from './link.svg';

export interface GenericPlugProps {
  title?: string | null;
  intro?: string | null;
  image?: ImageFragment | null;
  label?: string | null;
  name?: string | null;
  url?: string | null;
  large?: boolean;
  sizes?: string;
}

export const GenericPlug: React.FC<GenericPlugProps> = ({
  url = '',
  image,
  name,
  intro = '',
  title = '',
  label,
  large,
  sizes = '100vw',
}) => {
  let imageComponent = <BackgroundColors />;
  if (image?.responsiveImage?.src) {
    let placeholder: 'empty' | 'blur' = 'empty';
    if (image?.responsiveImage?.base64) {
      placeholder = 'blur';
    }
    imageComponent = (
      <Image
        src={image.responsiveImage.src}
        alt={image?.alt ?? ''}
        sizes={sizes}
        blurDataURL={image?.responsiveImage?.base64 ?? ''}
        placeholder={placeholder}
        fill
        loader={datoImageLoader}
      />
    );
  }
  return (
    <GenericPlugStyled>
      <Inner href={url ?? ''}>
        {imageComponent}
        <Content $inverted={!!image}>
          {label && <Label $inverted={!!image}>{label}</Label>}
          {name && <Name $inverted={!!image}>{name}</Name>}
          <Title $inverted={!!image} $large={large}>
            {title}
          </Title>
          {intro && (
            <Intro $inverted={!!image} $large={large}>
              {intro}
            </Intro>
          )}
          {url && (
            <LinkSvg
              width={39}
              height={18}
              aria-hidden={true}
              focusable={false}
            />
          )}
        </Content>
      </Inner>
    </GenericPlugStyled>
  );
};
