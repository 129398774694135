import dynamic from 'next/dynamic';

import { OfficeRecord } from '@hultafors/snickers/types';

import { Label } from './OfficeAccordion.styled';

const Accordion = dynamic(() =>
  import('../Accordion/Accordion').then((mod) => mod.Accordion),
);
const AccordionItem = dynamic(() =>
  import('../AccordionItem/AccordionItem').then((mod) => mod.AccordionItem),
);
const OfficeCard = dynamic(() =>
  import('../OfficeCard/OfficeCard').then((mod) => mod.OfficeCard),
);

interface OfficeAccordionProps {
  offices?: OfficeRecord[];
  viewOnMapLabel?: string;
  localWebLabel?: string;
}

export const OfficeAccordion: React.FC<OfficeAccordionProps> = ({
  offices = [],
  viewOnMapLabel = '',
  localWebLabel = '',
}) => {
  const officeMapper = (value: OfficeRecord) =>
    value ? (
      <AccordionItem
        key={`Office-${value.id}`}
        label={<Label>{value.name}</Label>}
      >
        <OfficeCard
          office={value}
          viewOnMapLabel={viewOnMapLabel}
          localWebLabel={localWebLabel}
        />
      </AccordionItem>
    ) : null;
  return offices?.length ? (
    <Accordion allowZeroExpanded>{offices.map(officeMapper)}</Accordion>
  ) : null;
};
