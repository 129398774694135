import {
  isProductDetailsApiImage,
  ProductDetailsApiDocument,
  ProductDetailsApiImage,
  ProductDetailsValue,
} from '@hultafors/shared/types';

import { ImageFragment } from '@hultafors/snickers/types';

import { Paragraph } from '../Paragraph/Paragraph';

import FileSvg from './file.svg';
import {
  Attribute,
  DocumentWrapper,
  ImageAttributeStyled,
  ImageWrapper,
  Title,
  TitleImage,
} from './ImageAttribute.styled';

export interface ImageAttributeProps {
  title?: string | null;
  attributes?: ProductDetailsValue[] | string;
  pageUrl?: string;
  attributeName?: string | null;
  image?: ImageFragment | ProductDetailsApiImage | null;
  document?: ProductDetailsApiDocument;
}

const ImageAttributeTitleImage: React.FC<ImageAttributeProps> = ({
  image,
  title,
}) => {
  if (isProductDetailsApiImage(image) && image.url) {
    return (
      <TitleImage
        src={image.url}
        alt={image.description ?? title ?? ''}
        width={24}
        height={24}
      />
    );
  }
  if (!isProductDetailsApiImage(image) && image?.responsiveImage?.src) {
    return (
      <TitleImage
        src={image.responsiveImage.src || ''}
        alt={image.alt ?? title ?? ''}
        width={24}
        height={24}
        blurDataURL={image.responsiveImage.base64 ?? undefined}
        placeholder={image.responsiveImage?.base64 ? 'blur' : undefined}
      />
    );
  }
  return null;
};

const ImageAttributeTitle: React.FC<ImageAttributeProps> = ({
  title,
  attributeName,
  document,
  image,
}) => {
  if (attributeName === 'documents' && document) {
    return (
      <DocumentWrapper href={document.url || ''} download>
        <ImageWrapper>
          <FileSvg
            aria-hidden={true}
            focusable={false}
            width={24}
            height={24}
          />
        </ImageWrapper>
        <Paragraph>
          {document.name} {document.size && <> ({document.size})</>}
        </Paragraph>
      </DocumentWrapper>
    );
  }
  if (title) {
    return (
      <Title $certifications={attributeName === 'certifications'}>
        <ImageAttributeTitleImage image={image} title={title} />
        {title}
      </Title>
    );
  }
  return null;
};

const ImageAttributeBody: React.FC<ImageAttributeProps> = ({
  attributes = [],
  attributeName,
}) => {
  if (attributes && typeof attributes === 'string') {
    return (
      <Attribute $type={attributeName}>
        <Paragraph>{attributes}</Paragraph>
      </Attribute>
    );
  }
  function attributesMapper(attr: any, i: number) {
    return (
      <Attribute key={`${attributeName}-${i}`} $type={attributeName}>
        {attr.image?.url && (
          <ImageWrapper>
            <img src={attr.image.url} alt={attr.text} height={24} />
          </ImageWrapper>
        )}
        <Paragraph>{attr.text}</Paragraph>
      </Attribute>
    );
  }
  if (Array.isArray(attributes) && attributes.length > 0) {
    return attributes.map(attributesMapper);
  }
  return null;
};

export const ImageAttribute: React.FC<ImageAttributeProps> = ({
  title,
  attributes = [],
  attributeName,
  image,
  document,
}) => {
  return (
    <ImageAttributeStyled>
      <ImageAttributeTitle
        title={title}
        attributeName={attributeName}
        document={document}
        image={image}
      />
      <ImageAttributeBody
        attributes={attributes}
        attributeName={attributeName}
      />
    </ImageAttributeStyled>
  );
};
