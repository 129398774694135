import { useMemo } from 'react';

import { useRouter } from 'next/router';
import useSWR from 'swr';

import {
  ParsedProduct,
  ParsedProductsApiResponse,
} from '@hultafors/shared/types';

import { RELATED_PRODUCTS_PAGE_SIZE } from '@hultafors/snickers/api';
import { useGlobal } from '@hultafors/snickers/hooks';
import { RelatedProductsBlockRecord } from '@hultafors/snickers/types';

import { LinkButton } from '../../../LinkButton/LinkButton';
import { Products } from '../../../Products/Products';
import { RelatedProducts } from '../../../RelatedProducts/RelatedProducts';
import { Section } from '../../../Section/Section';

import { ButtonWrapper, ProductGrid } from './RelatedProductsBlock.styled';

export type RelatedProductsBlockProps = Partial<RelatedProductsBlockRecord>;

export const RelatedProductsBlock: React.FC<RelatedProductsBlockProps> = ({
  ctaLink,
  ctaText,
  title,
  products: productCatalogNodeId,
}) => {
  const { global, settings } = useGlobal();
  const router = useRouter();
  const query = useMemo(() => {
    const searchParams = new URLSearchParams({
      includeFilters: 'false',
      pageSize: `${RELATED_PRODUCTS_PAGE_SIZE}`,
      productCatalogNodeId: `${productCatalogNodeId}`,
    });
    if (router.locale && router.locale !== 'com') {
      searchParams.set('locale', router.locale);
    }
    return searchParams.toString();
  }, [productCatalogNodeId, router.locale]);
  const { data, isLoading } = useSWR<ParsedProductsApiResponse<ParsedProduct>>(
    productCatalogNodeId &&
      `/api/productslist/${settings.pt?.productList}?${query}`,
  );

  return (
    <Section noTop>
      <RelatedProducts header={title ?? ''}>
        <ProductGrid>
          <Products
            products={data?.items}
            badgeText={global?.new ?? ''}
            rrpLabel={global.rrpLabel ?? ''}
            rrpIncludingVatLabel={global.rrpIncludingVatLabel ?? ''}
            isLoading={!!isLoading}
            numberOfItems={data?.paging?.pageCount ?? 4}
          />
        </ProductGrid>
        {ctaText && ctaLink && (
          <ButtonWrapper>
            <LinkButton large text={ctaText} url={ctaLink} className="Grey" />
          </ButtonWrapper>
        )}
      </RelatedProducts>
    </Section>
  );
};
