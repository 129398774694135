/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BigPlugsBlockFragment,
  BreadcrumbFragment,
  ContentPlugsBlockFragment,
  DotDigitalFormFragment,
  DoubleTextBlockFragment,
  DualImageFragment,
  FactBoxFragment,
  FeedBlockFragment,
  FindRetailersBlockFragment,
  GridOneThreeFragment,
  HeroBlockFragment,
  ImageBlockFragment,
  ImageFragment,
  ImageSliderFragment,
  ImageTextBlockDoubleFragment,
  ImageTextBlockFragment,
  ImageTextBlockSimpleFragment,
  LinkBlockFragment,
  MoreArticlesBlockFragment,
  OfficesFragment,
  PageNavigationFragment,
  PersonFragment,
  PersonInfoFragment,
  ProductContentPlugsBlockFragment,
  ProductPlugsBlockFragment,
  QuoteFragment,
  RelatedProductsBlockFragment,
  SliderBlockFragment,
  TextBlockFragment,
  VideoBlockFragment,
  VideoFragment,
  VisibleSeoBlockFragment,
} from '../dato';

export function isBigPlugsBlock(item: any): item is BigPlugsBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'BigPlugsBlockRecord';
}

export function isBreadcrumb(item: any): item is BreadcrumbFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'BreadcrumbRecord';
}

export function isContentPlugsBlock(
  item: any,
): item is ContentPlugsBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ContentPlugsBlockRecord';
}

export function isDotDigitalForm(item: any): item is DotDigitalFormFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'DotDigitalFormRecord';
}

export function isDoubleTextBlock(item: any): item is DoubleTextBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'DoubleTextBlockRecord';
}

export function isDualImage(item: any): item is DualImageFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'DualImageRecord';
}

export function isFactBox(item: any): item is FactBoxFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'FactBoxRecord';
}

export function isFeedBlock(item: any): item is FeedBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'FeedBlockRecord';
}

export function isGridOneThree(item: any): item is GridOneThreeFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'GridOneThreeRecord';
}

export function isHeroBlock(item: any): item is HeroBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'HeroBlockRecord';
}

export function isImage(item: any): item is ImageFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'FileField';
}

export function isImageBlock(item: any): item is ImageBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ImageRecord';
}

export function isImageSlider(item: any): item is ImageSliderFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ImageSliderRecord';
}

export function isImageTextBlock(item: any): item is ImageTextBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ImageTextBlockRecord';
}

export function isImageTextBlockDouble(
  item: any,
): item is ImageTextBlockDoubleFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ImageTextBlockDoubleRecord';
}

export function isImageTextBlockSimple(
  item: any,
): item is ImageTextBlockSimpleFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ImageTextBlockSimpleRecord';
}

export function isLinkBlock(item: any): item is LinkBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'LinkBlockRecord';
}

export function isMoreArticlesBlock(
  item: any,
): item is MoreArticlesBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'MoreArticlesBlockRecord';
}

export function isOffices(item: any): item is OfficesFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'OfficesBlockRecord';
}

export function isPageNavigation(item: any): item is PageNavigationFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'PageNavigationRecord';
}

export function isPerson(item: any): item is PersonFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'PersonRecord';
}

export function isPersonInfo(item: any): item is PersonInfoFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'PersonInfoRecord';
}

export function isProductContentPlugsBlock(
  item: any,
): item is ProductContentPlugsBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ProductContentPlugsBlockRecord';
}

export function isProductPlugsBlock(
  item: any,
): item is ProductPlugsBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ProductPlugsBlockRecord';
}

export function isQuote(item: any): item is QuoteFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'QuoteRecord';
}

export function isRelatedProductsBlock(
  item: any,
): item is RelatedProductsBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'RelatedProductsBlockRecord';
}

export function isSliderBlock(item: any): item is SliderBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'SliderBlockRecord';
}

export function isTextBlock(item: any): item is TextBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'TextBlockRecord';
}

export function isVideo(item: any): item is VideoFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'VideoRecord';
}

export function isVideoBlock(item: any): item is VideoBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'VideoBlockRecord';
}

export function isVisibleSeoBlock(item: any): item is VisibleSeoBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'VisibleSeoBlockRecord';
}

export function isFindRetailersBlock(
  item: any,
): item is FindRetailersBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'FindRetailersBlockRecord';
}
